import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from "react";
import { TransitionGroup } from "react-transition-group";
import FadeTransition from "../../components/common/Transition/FadeTransition";
import { useThemes } from "../../hooks/useThemes";
import themes from "../../themes/themes.module.css";
import { ChildrenProps } from "../../types";
import styles from "./NavContainer.module.css";
import { mainReducer } from "./reducers";
import {
  NavigationActionType,
  NavigationContextProps,
  ScreenType,
} from "./types";

const initialState = {
  screens: [],
  currentSes: 0,
};

const initialContext: NavigationContextProps = {
  navigation: initialState,
  onlyScreen: () => null,
  firstScreen: () => null,
  backScreen: () => null,
  backScreens: (numberOfScreens) => null,
  nextScreen: () => null,
  replaceScreen: () => null,
  currentStep: () => 0,
  currentScreenKey: () => "",
};

export const NavigationContext =
  createContext<NavigationContextProps>(initialContext);

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error(
      "useNavigationContext must be used within a NavigationContextProvider"
    );
  }
  return context;
};

export const NavigationContextProvider: FC<ChildrenProps> = (
  props: ChildrenProps
) => {
  const [navigation, dispatch] = useReducer(mainReducer, initialState);

  const firstScreen = useCallback(
    () => dispatch({ type: NavigationActionType.First }),
    []
  );
  const backScreen = useCallback(
    () => dispatch({ type: NavigationActionType.Pop }),
    []
  );
  const backScreens = useCallback(
    (numberOfScreens: number) =>
      dispatch({ type: NavigationActionType.BackScreens, numberOfScreens }),
    []
  );
  const nextScreen = useCallback(
    (screen: ScreenType) =>
      dispatch({ type: NavigationActionType.Push, screen }),
    []
  );
  const onlyScreen = useCallback(
    (screen: ScreenType) =>
      dispatch({ type: NavigationActionType.Only, screen }),
    []
  );
  const replaceScreen = useCallback(
    (screen: ScreenType) =>
      dispatch({ type: NavigationActionType.Replace, screen }),
    []
  );
  const currentStep = useCallback(
    () => navigation.screens.length - 1,
    [navigation.screens]
  );
  const currentScreenKey = useCallback(() => {
    const screen = navigation.screens[navigation.screens.length - 1];
    if (typeof screen === "object" && screen !== null && "key" in screen) {
      return screen.key as string;
    }
    return "";
  }, [navigation.screens]);

  const value = useMemo(
    () => ({
      navigation,
      onlyScreen,
      backScreen,
      backScreens,
      nextScreen,
      replaceScreen,
      firstScreen,
      currentStep,
      currentScreenKey,
    }),
    [
      navigation,
      onlyScreen,
      backScreen,
      backScreens,
      nextScreen,
      replaceScreen,
      firstScreen,
      currentStep,
      currentScreenKey,
    ]
  );

  return (
    <NavigationContext.Provider value={value}>
      {props.children}
    </NavigationContext.Provider>
  );
};

type NavigationContainerProps = {
  home: ScreenType;
};

export const NavigationContainer: FC<NavigationContainerProps> = (
  props: NavigationContainerProps
) => {
  const { home } = props;
  const transitionRef = useRef(null);
  const navigationContext = useNavigationContext();
  const { getCustomThemeStyles, getThemeClass } = useThemes();

  // let timer = null;
  useEffect(() => {
    const firstScreen = home;
    // eslint-disable-next-line curly
    if (firstScreen) navigationContext.onlyScreen(firstScreen);

    // timer = setTimeout(() => {
    //   // this.setState({ intro: true });
    //   // if (this.timer) clearTimeout(timer);
    //   // this.timer = setTimeout(() => {
    //   //   this.setState({ intro: false });
    //   //   if (this.timer) clearTimeout(timer);
    //   // }, 800);
    // }, 900);
  }, []);

  return (
    <NavigationContext.Consumer>
      {(value) => (
        <div
          id="nav-container"
          className={`isolate-inheritance ${themes.theme} ${themes[getThemeClass()]} ${styles["nav-container"]}`}
          style={getCustomThemeStyles()}
        >
          <TransitionGroup>
            {value.navigation.screens.map((screen, i) => (
              // <CSSTransition
              //   key={i}
              //   nodeRef={transitionRef}
              //   timeout={200}
              //   classNames={{
              //     enter: styles['screen-enter'],
              //     enterActive: styles['screen-enter-active'],
              //     exit: styles['screen-exit'],
              //     exitActive: styles['screen-exit-active'],
              //   }}
              // >
              <FadeTransition
                key={i}
                timeout={200}
                classNames={{
                  enter: styles["screen-enter"],
                  enterActive: styles["screen-enter-active"],
                  exit: styles["screen-exit"],
                  exitActive: styles["screen-exit-active"],
                }}
              >
                <div
                  key={`${value.navigation.currentSes}${i}`}
                  style={{ zIndex: i + 1 }}
                  className={styles.screen}
                  ref={transitionRef}
                >
                  {screen}
                </div>
                {/* </CSSTransition> */}
              </FadeTransition>
            ))}
          </TransitionGroup>
        </div>
      )}
    </NavigationContext.Consumer>
  );
};
