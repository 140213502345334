import React, { FC, useMemo } from "react";
import styles from "./AmountSummary.module.css";
import { ReactComponent as Arrow } from "../../../icons/arrow-right.svg";
import { roundNumber } from "../../../utils/commonUtils";
import { useTransactionContext } from "../../../providers";

const AmountSummary: FC = () => {
  const { transaction } = useTransactionContext();
  const {
    fiatAmount,
    cryptoAmount,
    transactionType,
    selectedFiat,
    selectedCrypto,
  } = transaction;

  const sourceAmount = useMemo(() => {
    return transactionType === "sell" ? cryptoAmount : fiatAmount;
  }, [transactionType, cryptoAmount, fiatAmount]);

  const destinationAmount = useMemo(() => {
    return transactionType === "sell" ? fiatAmount : cryptoAmount;
  }, [transactionType, cryptoAmount, fiatAmount]);

  const sourceCurrency = useMemo(() => {
    return transactionType === "sell"
      ? selectedCrypto?.code
      : selectedFiat?.code;
  }, [transactionType, selectedCrypto, selectedFiat]);

  const destinationCurrency = useMemo(() => {
    return transactionType === "sell"
      ? selectedFiat?.code
      : selectedCrypto?.code;
  }, [transactionType, selectedCrypto, selectedFiat]);

  return (
    <div className={styles["amount-summary"]}>
      <span>
        {roundNumber(sourceAmount, 8)} {sourceCurrency}
      </span>
      <Arrow />
      <span>
        {roundNumber(destinationAmount, 8)} {destinationCurrency}
      </span>
    </div>
  );
};

export default AmountSummary;
