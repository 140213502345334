import { CryptoCurrency, QuoteError } from '../../types';

export type Quote = {
  quoteId: string;
  provider: string;
  rate: number;
  inCurrency: string;
  outCurrency: string;
  networkFee: number;
  payout: number;
  errors: QuoteError[];
};

export type Quotes = Quote[];

export type Provider = {
  id: string;
  name: string;
  icon: string;
  supportLink?: string;
  rate: number;
  payout: number;
  isAvailable?: boolean;
  isMock?: boolean;
  errors?: QuoteError[];
  quoteId?: string;
};

export type SwapError = 'ClientLimitError' | 'ServerLimitError' | 'QuoteError';

export type SwapTransaction = {
  selectedSource: CryptoCurrency | null;
  selectedTarget: CryptoCurrency | null;
  selectedProvider: Provider | null;
  sourceAmount: number;
  targetAmount: number;
  sourceWalletAddress: string | null;
  sourceWalletAddressTag?: string | null;
  targetWalletAddress: string | null;
  targetWalletAddressTag?: string | null;
  transactionHash?: string | null;
  txId?: string | null;
  quotes: Quotes | null;
  error?: SwapError | null;
};


export type SwapContextProps = {
  swapTransaction: SwapTransaction;
  setSelectedSource: (currency: CryptoCurrency | null) => void;
  setSelectedTarget: (currency: CryptoCurrency | null) => void;
  setSelectedProvider: (onramp: Provider | null) => void;
  setQuotes: (quotes: Quotes | null) => void;
  setSourceAmount: (amount: number) => void;
  setTargetAmount: (amount: number) => void;
  setSourceWalletAddress: (address: string | null) => void;
  setSourceWalletAddressTag: (tag: string | null) => void;
  setTargetWalletAddress: (address: string | null) => void;
  setTargetWalletAddressTag: (tag: string | null) => void;
  setTransactionHash: (hash: string | null) => void;
  setTxId: (txId: string | null) => void;
  resetSwap: () => void;
  setSwapError: (error: SwapError | null) => void;
};

export enum SwapActionType {
  SetSelectedSource = 'SET_SELECTED_SOURCE',
  SetSelectedTarget = 'SET_SELECTED_TARGET',
  SetSourceAmount = 'SET_SOURCE_AMOUNT',
  SetTargetAmount = 'SET_TARGET_AMOUNT',
  SetSourceWalletAddress = 'SET_SOURCE_WALLET_ADDRESS',
  SetSourceWalletAddressTag = 'SET_SOURCE_WALLET_ADDRESS_TAG',
  SetTargetWalletAddress = 'SET_TARGET_WALLET_ADDRESS',
  SetTargetWalletAddressTag = 'SET_TARGET_WALLET_ADDRESS_TAG',
  SetSelectedProvider = 'SET_SELECTED_PROVIDER',
  SetTransactionHash = 'SET_TRANSACTION_HASH',
  SetTxId = 'SET_TX_ID',
  SetQuotes = 'SET_QUOTES',
  ResetSwap = 'RESET_SWAP',
  SetSwapError = 'SWAP_ERROR',
}

export type SwapActions =
  | {
      type: SwapActionType.SetSelectedSource;
      payload: CryptoCurrency | null;
    }
  | {
      type: SwapActionType.SetSelectedTarget;
      payload: CryptoCurrency | null;
    }
  | {
      type: SwapActionType.SetSourceAmount;
      payload: number;
    }
  | {
      type: SwapActionType.SetTargetAmount;
      payload: number;
    }
  | {
      type: SwapActionType.SetSourceWalletAddress;
      payload: string | null;
    }
  | {
      type: SwapActionType.SetSourceWalletAddressTag;
      payload: string | null;
    }
  | {
      type: SwapActionType.SetTargetWalletAddress;
      payload: string | null;
    }
  | {
      type: SwapActionType.SetTargetWalletAddressTag;
      payload: string | null;
    }
  | {
      type: SwapActionType.SetSelectedProvider;
      payload: Provider | null;
    }
  | {
      type: SwapActionType.SetTransactionHash;
      payload: string | null;
    }
  | {
      type: SwapActionType.SetTxId;
      payload: string | null;
    }
  | {
      type: SwapActionType.SetQuotes;
      payload: Quotes | null;
    }
  | { type: SwapActionType.ResetSwap; payload: null }
  | { type: SwapActionType.SetSwapError; payload: SwapError | null }
