import React, { useEffect, useState } from "react";
import { useMeshCheckoutIntent } from "../../../hooks/useMeshCheckoutIntent";
import { useMeshLink } from "../../../hooks/useMeshLink";
import { useNavigationContext } from "../../../providers";
import { useAuthContext } from "../../../providers/AuthContextProvider";
import ContainerView from "../ContainerView";
import { SignIn } from "../SignInView";

type MeshConnectViewProps = {
  selectedIntegration?: string | null;
};

export const MeshConnectView: React.FC<MeshConnectViewProps> = ({
  selectedIntegration,
}) => {
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const {
    auth: { isAuthenticated },
  } = useAuthContext();
  const { nextScreen, onlyScreen } = useNavigationContext();

  const handleExit = () => {
    onlyScreen(<ContainerView />);
  };

  const {
    checkoutIntentResponse,
    isLoading,
    isError,
    mutate: checkout,
    error,
  } = useMeshCheckoutIntent();

  useEffect(() => {
    if (checkout) checkout();
  }, [checkout]);

  useEffect(() => {
    if (checkoutIntentResponse) {
      setLinkToken(checkoutIntentResponse?.content?.linkToken);
    }
  }, [checkoutIntentResponse]);

  useMeshLink({
    linkToken,
    onTransferFinished: () => undefined,
    onExit: handleExit,
    selectedIntegration: selectedIntegration,
  });

  useEffect(() => {
    if (!isAuthenticated) nextScreen(<SignIn signInFrom="mesh" />);
  }, [isAuthenticated, nextScreen]);

  return <></>;
};
