import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ChildrenProps } from '../../types';

export const ReactQueryProvider = ({ children }: ChildrenProps) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        enabled: true,
        staleTime: 5 * 60 * 1000,
        cacheTime: 6 * 60 * 1000,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        refetchOnMount: true,
        retryOnMount: true,
        retry: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
