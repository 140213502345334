import React, { FC } from 'react';
import LoadingSpinner from '../../common/LoadingSpinner';

import styles from './LoadingView.module.css';
import { LoadingViewProps } from './LoadingViewProps';

const LoadingView: FC<LoadingViewProps> = (props: LoadingViewProps) => {
  const { title, description } = props;
  return (
    <div className={styles['loading-view-root']}>
      <LoadingSpinner />
      {title && <p className={styles['spinner-title']}>{title}</p>}
      {description && <p className={styles['spinner-description']}>{description}</p>}
    </div>
  );
};
export default LoadingView;
