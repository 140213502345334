import { Banner } from '@onramper/oui';
import React, { useRef, useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useNotificationContext } from '../../../providers';
import { Notification } from '../../../providers/NotificationContextProvider';
import classes from './WidgetNotification.module.css';
import { WidgetNotificationProps } from './WidgetNotificationProps';

type SingleNotificationProps = {
  notification: Omit<Notification, 'submittedAt' | 'id' | 'shouldExpire'>;
  className?: string;
};

export enum NotificationType {
  Warning = 'Warning',
  Success = 'Success',
  Info = 'Info',
  Error = 'Error',
}

// const typeToIcon = {
//   [NotificationType.Warning]: Warning,
//   [NotificationType.Info]: Info,
//   [NotificationType.Success]: Success,
//   [NotificationType.Error]: Error,
// };

export const SingleNotification = ({ notification }: SingleNotificationProps) => (
  <Banner title={notification.title} description={notification.message} variant={notification.type} showClose={false} />
);

export const WidgetNotification = ({ className }: WidgetNotificationProps) => {
  const { notifications } = useNotificationContext();
  const transitionRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(true);
  }, []);

  const notificationToRender = notifications.length > 0 ? notifications.at(-1) : null;
  return notificationToRender ? (
    <CSSTransition
      nodeRef={transitionRef}
      in={isActive}
      timeout={600}
      classNames={{
        enter: classes['collapse-enter'],
        enterActive: classes['collapse-enter-active'],
        exit: classes['collapse-exit'],
        exitActive: classes['collapse-exit-active'],
      }}
      unmountOnExit={true}
    >
      <div ref={transitionRef}>
        <SingleNotification className={className} notification={notificationToRender} />
      </div>
    </CSSTransition>
  ) : (
    <></>
  );
};
