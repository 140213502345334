import { Icon } from '@onramper/oui';
import React, { FC } from 'react';
import { ReactComponent as Arrow } from '../../../icons/arrow-right.svg';


import { Onramp } from '../../../types';
import LoadingArrow from '../../common/LoadingArrow';
import styles from './LoadingCheckoutView.module.css';
import { roundNumber } from '../../../utils/commonUtils';
import { useLanguage } from '../../../hooks/useLanguage';
import Branding from '../../common/Branding';

type LoadingCheckoutViewProps = {
  onramp: Onramp | null;
  source?: string;
  destination?: string;
  sourceAmount?: number;
  destinationAmount?: number;
};

const LoadingCheckoutView: FC<LoadingCheckoutViewProps> = (props: LoadingCheckoutViewProps) => {
  const { onramp, source, sourceAmount, destination, destinationAmount } = props;
  const { formatMessage } = useLanguage();

  return (
    <div className={styles['loading-view-root']}>
      <div className={styles['icon-container']}>
        <Icon size="xl" format="svg" renderSvgIcon={() => <Branding type='rounded' size='lg'/>} />
        <LoadingArrow />
        <Icon size="xl" format="base64" iconSrc={onramp?.icon} />
      </div>

      <p className={styles['spinner-title']}>{formatMessage('loadingCheckoutView.message', {
        provider: onramp?.name,
      })}</p>
      {source && destination && (
        <div className={styles['spinner-description']}>
          <p>
            {sourceAmount && roundNumber(sourceAmount, 8)} {source.toUpperCase()}
          </p>
          <Arrow />
          <p>
            {destinationAmount && roundNumber(destinationAmount, 8)} {destination.toUpperCase()}
          </p>
        </div>
      )}
    </div>
  );
};

export default LoadingCheckoutView;
