
import { initialState } from './initialState';
import { SwapActionType, SwapActions, SwapTransaction } from './types';


export const swapReducer = (state: SwapTransaction, action: SwapActions) => {
  switch (action.type) {

    case SwapActionType.SetSourceAmount:
      return { ...state, sourceAmount: action.payload };
    case SwapActionType.SetTargetAmount:
      return { ...state, targetAmount: action.payload };
    case SwapActionType.SetSelectedSource:
      return { ...state, selectedSource: action.payload };
    case SwapActionType.SetSelectedTarget:
      return { ...state, selectedTarget: action.payload };
    case SwapActionType.SetSelectedProvider:
      return { ...state, selectedProvider: action.payload };
    case SwapActionType.SetQuotes:
      return { ...state, quotes: action.payload };
    case SwapActionType.SetSourceWalletAddress:
      return { ...state, sourceWalletAddress: action.payload };
    case SwapActionType.SetSourceWalletAddressTag:
      return { ...state, sourceWalletAddressTag: action.payload };
    case SwapActionType.SetTargetWalletAddress:
      return { ...state, targetWalletAddress: action.payload };
    case SwapActionType.SetTargetWalletAddressTag:
      return { ...state, targetWalletAddressTag: action.payload };
    case SwapActionType.SetTransactionHash:
      return { ...state, transactionHash: action.payload };
    case SwapActionType.SetTxId:
      return { ...state, txId: action.payload };
    case SwapActionType.ResetSwap:
      return initialState;
    case SwapActionType.SetSwapError:
      return { ...state, error: action.payload };
    default:
      throw new Error('Unhandled action type');
  }
};
