import { NotificationActions, NotificationActionType, Notifications } from './types';

export const notificationReducer = (state: Notifications, action: NotificationActions) => {
  switch (action.type) {
    case NotificationActionType.AddNotification:
      return [...state, action.notification];
    case NotificationActionType.RemoveNotification:
      return state.filter((n) => n.id !== action.id);
    case NotificationActionType.RemoveAllNotifications:
      return [];
    default:
      throw new Error('Unhandled action type');
  }
};
