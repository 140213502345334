import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useLanguage = () => {
  const { t, i18n } = useTranslation();

  const formatMessage = useCallback((key: string, options?: Record<string, unknown>) => t(key, options), [t]);

  const changeLanguage = async (lang: string) => {
    try {
      await i18n.changeLanguage(lang);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error while changing language', e);
    }
  };

  const getLanguage = () => i18n.resolvedLanguage;

  const getAvailableLanguages = () => i18n.languages;

  const getLanguageDirection = () => {
    const currentLanguage = i18n.resolvedLanguage || 'en';
    return ['ar', 'he', 'fa'].includes(currentLanguage) ? 'rtl' : 'ltr';
  };

  return {
    formatMessage,
    changeLanguage,
    getLanguage,
    getAvailableLanguages,
    getLanguageDirection,
  };
};
