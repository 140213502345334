import { Button } from '@onramper/oui';
import React, { FC } from 'react';
import { ReactComponent as ErrorIllustration } from '../../../icons/errorIllustration.svg';
import { ReactComponent as UnsupportedRegionIllustration } from '../../../icons/error-unsupported-region.svg';

import { useNavigationContext } from '../../../providers';
import ContainerView from '../ContainerView';

import styles from './ErrorView.module.css';
import { useLanguage } from '../../../hooks/useLanguage';

type ErrorViewProps = {
  title?: string;
  description?: string;
  buttonLabel?: string;
  buttonClickHandler?: () => void;
  displayButton?: boolean;
  errorType: 'RegionUnsupported' | 'Generic' | 'Checkout' | 'DemoKey' | 'Swap';
};

const ErrorView: FC<ErrorViewProps> = (
  { title, description, buttonLabel, buttonClickHandler, displayButton = true, errorType }: ErrorViewProps) => {
  const { onlyScreen } = useNavigationContext();
  const { formatMessage } = useLanguage();

  const tryAgainButtonClickHandler = () => {
    onlyScreen(<ContainerView selectedTabId={0} />);
  };

  const contactSalesTeamHandler = () => {
    if(window && window.top){
      window.top.location.href = 'https://onramper.com/contact-sales';
    }
    
  }

  const renderIllustration = () => {
    if (errorType === 'RegionUnsupported') {
      return <UnsupportedRegionIllustration stroke="var(--primary-text-color)" />
    }
    return <ErrorIllustration />
  }

  const renderCTA = () => {
    if(errorType === 'DemoKey'){
      return ( <Button
        label={formatMessage('errorView.ctaButton.contactSales')}
        size="large-rectangle"
        expanded
        onClick={contactSalesTeamHandler}
      />);
    }
    return ( <Button
      label={buttonLabel ?? formatMessage('errorView.ctaButton.tryAgain')}
      size="large-rectangle"
      expanded
      onClick={buttonClickHandler ?? tryAgainButtonClickHandler}
    />);
  }

  return (
    <div className={styles['error-view-root']}>
      <div className={styles['error-illustration-container']}>
        {renderIllustration()}
      </div>
      <p className={styles['error-title']}>{title}</p>
      {description && <p className={styles['error-description']}>{description}</p>}

      {
        displayButton && (
          <div className={styles['try-again-button']}>
           {renderCTA()}
          </div>
        )
      }

    </div>
  );
};

export default ErrorView;
