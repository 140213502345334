import type { Auth } from "../../types";

export type AuthContextProps = {
  auth: Auth;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  setAuthToken: (authToken: string | null) => void;
  setOnramperId: (onramperId: string | null) => void;
  setEmail: (email: string | null) => void;
  setOtp: (otp: string) => void;
  setAuthError: (authError: AuthErrorType) => void;
};

export type AuthErrorType =
  | "None"
  | "FailedToRefreshToken"
  | "FailedToSignIn"
  | "FailedToVerifyOtp";

export enum AuthActionType {
  SetIsAuthenticated = "SET_IS_AUTHENTICATED",
  SetAuthToken = "SET_AUTH_TOKEN",
  SetOnramperId = "SET_ONRAMPER_ID",
  SetEmail = "SET_EMAIL",
  SetOtp = "SET_OTP",
  SetAuthError = "SET_AUTH_ERROR",
}

export type AuthActions =
  | {
      type: AuthActionType.SetIsAuthenticated;
      payload: boolean;
    }
  | {
      type: AuthActionType.SetAuthToken;
      payload: string | null;
    }
  | {
      type: AuthActionType.SetOnramperId;
      payload: string | null;
    }
  | {
      type: AuthActionType.SetEmail;
      payload: string | null;
    }
  | {
      type: AuthActionType.SetOtp;
      payload: string;
    }
  | {
      type: AuthActionType.SetAuthError;
      payload: AuthErrorType;
    };
