import { TextField } from '@onramper/oui';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { useRef, useState } from 'react';
import { ReactComponent as CloseBox } from '../../../icons/close-box.svg';
import { ReactComponent as MagnifierIcon } from '../../../icons/magnifier.svg';
import styles from './SelectionView.module.css';
import { SelectionViewProps } from './SelectionViewProps';

const SelectionView = <T,>(props: SelectionViewProps<T>): JSX.Element => {
  const { items, searchable, gutter, itemHeight, renderItem, onSelectItem, onSearch } = props;
  const [searchkey, setSearchKey] = useState('');
  const parentRef = useRef<HTMLDivElement | null>(null);

  const { getTotalSize, getVirtualItems, measureElement } = useVirtualizer({
    count: items?.length ?? 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => itemHeight ?? 85,
    overscan: 10,
  });

  const renderCloseBox = () => (
    <div
      onClick={() => {
        setSearchKey('');
        onSearch?.('');
      }}
    >
      <CloseBox />
    </div>
  );

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKey(e.target.value);
    onSearch?.(e.target.value);
  };

  return (
    <div className={styles['selection-view']}>
      {searchable && (
        <div className={styles['selection-view-search']}>
          <TextField
            expanded
            leftIcon={<MagnifierIcon />}
            rightIcon={searchkey.length > 0 ? renderCloseBox() : <></>}
            onChange={searchHandler}
            value={searchkey}
          />
        </div>
      )}

      {items && (
        <div
          ref={parentRef}
          style={{
            height: '100%',
            overflow: 'auto',
            padding: `${gutter}px`,
          }}
        >
          <div
            style={{
              height: `${getTotalSize()}px`,
              width: '100%',
              position: 'relative',
            }}
          >
            {getVirtualItems().map((virtualItem, index) => {
              const item = items[virtualItem.index];
              return (
                <div
                  key={virtualItem.index}
                  data-index={virtualItem.index}
                  ref={measureElement}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    transform: `translateY(${virtualItem.start}px)`,
                  }}
                  onClick={() => {
                    onSelectItem?.(item, virtualItem.index);
                  }}
                >
                  {renderItem(item, index)}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectionView;
