import { Button } from '@onramper/oui';
import React, { FC } from 'react';
import { ReactComponent as CloseIcon } from '../../../../icons/close.svg';
import { ReactComponent as Danger } from '../../../../icons/danger.svg';
import { ReactComponent as Lock } from '../../../../icons/lock.svg';

import { useNavigationContext, useParamContext, useTransactionContext } from '../../../../providers';
import AlertView from '../../AlertView';
import ContainerView from '../../ContainerView';

import styles from './IframeView.module.css';
import { useLanguage } from '../../../../hooks/useLanguage';
import Branding from '../../../common/Branding';

type iframeViewProps = {
  type: 'onramp' | 'offramp';
  url?: string;
  params?: {
    permissions?: string;
  };
  onClose?: () => void;
};

const IframeView: FC<iframeViewProps> = (props: iframeViewProps) => {
  const { formatMessage } = useLanguage();
  const { backScreen, currentScreenKey, nextScreen, firstScreen, onlyScreen } = useNavigationContext();
  const { url, params } = props;
  const { params: widgetParams } = useParamContext();
  const { skipTransactionScreen, preventTxnClose } = widgetParams;

  const onLoadHandler = () => {
    if (currentScreenKey() === 'LoadingView') {
      backScreen();
    }
  };

  const getHostName = () => {
    const newUrl = url && new URL(url);
    const hostname = newUrl && newUrl.hostname;
    return hostname || '';
  };

  const renderAlertView = () => (
    <AlertView
      title={formatMessage('iframeView.alert.title')}
      description={formatMessage('iframeView.alert.description')}
      icon={<Danger />}
      actionButton={
        <Button
          label={formatMessage('iframeView.alert.button.cancel')}
          size="large-rectangle"
          expanded
          onClick={
            skipTransactionScreen
              ? () => onlyScreen(<ContainerView selectedTabId={0} />)
              : () => {
                  if (props.type === 'offramp') {
                    onlyScreen(<ContainerView selectedTabId={0} />);
                  } else {
                    firstScreen();
                  }
                }
          }
          className={styles['transaction-action-button']}
        />
      }
    />
  );
  return (
    <>
      <div className={styles['iframe-header']}>
        <div className={styles['iframe-header-logo']}>
          <Branding type='rounded' size='sm'/>
        </div>
        <div className={styles['iframe-header-url']}>
          <Lock />
          <div className={styles['iframe-header-text']}>{getHostName()}</div>
        </div>
        {!preventTxnClose && (
          <div className={styles['iframe-header-close']} onClick={() => nextScreen(renderAlertView())}>
            <CloseIcon className={styles['header-svg']} />
          </div>
        )}
      </div>
      <div className={styles['iframe-container']}>
        <iframe
          title="sandbox"
          src={url}
          style={{
            width: '100%',
            minHeight: '100%',
            borderStyle: 'none',
          }}
          onLoad={onLoadHandler}
          allow={`${params?.permissions}`}
          onScroll={() => getHostName()}
          referrerPolicy="origin"
        />
      </div>
    </>
  );
};

export default IframeView;
