import React, { createContext, FC, useCallback, useContext, useMemo, useReducer } from 'react';
import { ChildrenProps } from '../../types';
import { notificationReducer } from './reducers';
import { AddNotificationPayload, NotificationActionType, NotificationContextProps, Notifications } from './types';
import { useInterval } from '../../hooks/useInterval';

const initialContext: NotificationContextProps = {
  notifications: [],
  addNotification: () => undefined,
  removeNotification: () => undefined,
  removeAllNotifications: () => undefined,
};

const NotificationContext = createContext<NotificationContextProps>(initialContext);

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotificationContext must be used within a NotificationContextProvider');
  }
  return context;
};

export const NotificationContextProvider: FC<ChildrenProps> = (props: ChildrenProps) => {
  const [notifications, dispatch] = useReducer(notificationReducer, []);

  const checkInterval = 1000; // ms
  const expirationPeriod = 5000; // ms

  function getExpiredNotifications(notifications: Notifications, expirationPeriod: number) {
    const timeFromCreation = (creationTime: number) => Date.now() - creationTime;

    return notifications.filter(
      (notification) => notification.shouldExpire && timeFromCreation(notification.submittedAt) >= expirationPeriod,
    );
  }

  useInterval(() => {
    const expiredNotification = getExpiredNotifications(notifications, expirationPeriod);
    for (const notification of expiredNotification) {
      notification.id && removeNotification(notification.id);
    }
  }, checkInterval);

  const addNotification = useCallback(
    ({ message, type, shouldExpire, id, title }: AddNotificationPayload) => {
      dispatch({
        type: NotificationActionType.AddNotification,
        notification: {
          submittedAt: Date.now(),
          title,
          message,
          type,
          id,
          shouldExpire,
        },
      });
    },
    [dispatch],
  );

  const removeNotification = useCallback(
    (id: string) => {
      dispatch({
        type: NotificationActionType.RemoveNotification,
        id,
      });
    },
    [dispatch],
  );

  const removeAllNotifications = useCallback(() => {
    dispatch({
      type: NotificationActionType.RemoveAllNotifications,
    });
  }, [dispatch]);

  const value = useMemo(
    () => ({ notifications, addNotification, removeNotification, removeAllNotifications }),
    [notifications, addNotification, removeNotification, removeAllNotifications],
  );

  return <NotificationContext.Provider value={value}>{props.children}</NotificationContext.Provider>;
};

export default NotificationContextProvider;
