import React from "react";
import { ChildrenProps } from "../../types";
import { useParamContext } from "../ParamContextProvider";
import { WalletConnectProvider } from "./WalletConnectProvider";

export function WalletConnectConditionalWrapper({ children }: ChildrenProps) {
  const {
    params: { supportSwap },
  } = useParamContext();

  if (!supportSwap) return <>{children}</>;
  else {
    return <WalletConnectProvider>{children}</WalletConnectProvider>;
  }
}
