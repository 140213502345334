import {
  createLink,
  Link,
  LinkPayload,
  SessionSymmary,
  TransferFinishedPayload,
} from "@meshconnect/web-link-sdk";
import { useEffect, useState } from "react";
import * as endpoints from "../config/endpoints";
import { useMeshFetchAccessTokens } from "../hooks/useMeshFetchAccessTokens";
import { useIndexedDbContext, useParamContext } from "../providers";
import { fetchApi } from "../utils/fetchApi";

// Utility to save access tokens
const saveMeshAccessTokens = async (
  accessToken: LinkPayload["accessToken"],
  authToken: string,
  apiKey?: string | null,
) => {
  if (!accessToken || !apiKey) return;

  const payload = {
    accountTokens: accessToken.accountTokens,
    brokerBrandInfo: accessToken.brokerBrandInfo,
    expiresInSeconds: accessToken.expiresInSeconds,
    refreshTokenExpiresInSeconds: accessToken.refreshTokenExpiresInSeconds,
    brokerType: accessToken.brokerType,
    brokerName: accessToken.brokerName,
  };

  try {
    const response = await fetchApi.post(
      endpoints.meshAccessTokens,
      {
        "Content-Type": "application/json",
        "X-Onramper-Auth": authToken,
        Authorization: apiKey,
      },
      payload,
    );

    if (!response.status) {
      throw new Error(`Failed to save access token: ${response.statusText}`);
    }
  } catch (error) {
    throw error;
  }
};

export const useMeshLink = ({
  linkToken,
  onTransferFinished,
  onExit,
  selectedIntegration,
}: {
  linkToken?: string | null;
  onTransferFinished?: (payload: TransferFinishedPayload) => void;
  onExit?: (error?: string) => void;
  selectedIntegration?: string | null;
}) => {
  const { params } = useParamContext();
  const [linkConnection, setLinkConnection] = useState<Link | null>(null);
  const { authToken } = useIndexedDbContext();
  const { accessTokens, tokensFetched } = useMeshFetchAccessTokens(
    endpoints.meshAccessTokens,
  );

  const selectedAccessTokens = selectedIntegration
    ? accessTokens?.filter((token) => token.brokerType === selectedIntegration)
    : undefined;

  const initializeMeshLink = () => {
    if (!process.env.REACT_APP_MESH_CLIENT_ID) {
      return;
    }

    const connection = createLink({
      clientId: process.env.REACT_APP_MESH_CLIENT_ID,
      accessTokens: selectedAccessTokens || undefined,
      onIntegrationConnected: async (payload: LinkPayload) => {
        try {
          await saveMeshAccessTokens(
            payload.accessToken,
            authToken,
            params.apiKey,
          );
        } catch (error) {
          console.error("Error saving access token:", error);
        }
      },
      onExit: (error?: string, summary?: SessionSymmary) => {
        if (error) {
        }
        if (summary) {
        }
        onExit?.();
      },
      onTransferFinished: (transferData: TransferFinishedPayload) => {
        console.info("[FRONT TRANSFER FINISHED]", transferData);
        onTransferFinished?.(transferData);
      },
    });

    setLinkConnection(connection);
  };

  // Initialize Mesh connection when tokens are fetched
  useEffect(() => {
    if (tokensFetched && !linkConnection) {
      initializeMeshLink();
    }
  }, [tokensFetched, selectedAccessTokens, linkConnection]);

  // Open link when linkToken is set
  useEffect(() => {
    if (linkToken && linkConnection) {
      linkConnection.openLink(linkToken);
    }
  }, [linkConnection, linkToken]);

  return linkConnection;
};
