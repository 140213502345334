import { useCallback } from 'react';
import * as endpoints from '../config/endpoints';
import { useParamContext } from '../providers/ParamContextProvider/ParamContextProvider';
import type { FiatCurrency, CryptoCurrency, TransactionType } from '../types';
import { getObjectsInSuppliedArray, removeObjectsInSuppliedArray } from '../utils/commonUtils';
import { useFetch } from '../utils/reactQuery';
import { useTransactionContext } from '../providers';

type SupportedCurrencies = {
  message: {
    crypto: CryptoCurrency[];
    fiat: FiatCurrency[];
  };
};

type FetchParams = {
  country?: string;
  type?: TransactionType;
};

export const useCurrencies = () => {
  const { params } = useParamContext();
  const { transaction } = useTransactionContext();
  const { transactionType } = transaction;
  const { onlyFiats, onlyCryptos, onlyCryptoNetworks, excludeCryptos, excludeCryptoNetworks, excludeFiats } =
    transactionType ? params[transactionType] : params.buy;
  const { country } = params;
  const fetchParams: FetchParams = { type: transactionType ?? 'buy' };
  if (country) {
    fetchParams.country = country;
  }
  const { data, isLoading, isError, error } = useFetch<SupportedCurrencies>(endpoints.supported, fetchParams);

  let filteredCryptos = data?.message.crypto;
  let filteredFiats = data?.message.fiat;

  if (data?.message.crypto) {
    if (onlyCryptos) {
      filteredCryptos = getObjectsInSuppliedArray(data?.message.crypto, 'id', onlyCryptos.toLowerCase().split(','));
    } else if (onlyCryptoNetworks) {
      filteredCryptos = getObjectsInSuppliedArray(
        data?.message.crypto,
        'network',
        onlyCryptoNetworks.toLowerCase().split(','),
      );
    } else if (excludeCryptos) {
      filteredCryptos = removeObjectsInSuppliedArray(
        data?.message.crypto,
        'id',
        excludeCryptos.toLowerCase().split(','),
      );
    } else if (excludeCryptoNetworks) {
      filteredCryptos = removeObjectsInSuppliedArray(
        data?.message.crypto,
        'network',
        excludeCryptoNetworks.toLowerCase().split(','),
      );
    }
  }

  if (data?.message.fiat) {
    if (onlyFiats) {
      filteredFiats = getObjectsInSuppliedArray(data?.message.fiat, 'id', onlyFiats.toLowerCase().split(','));
    } else if (excludeFiats) {
      filteredFiats = removeObjectsInSuppliedArray(data?.message.fiat, 'id', excludeFiats.toLowerCase().split(','));
    }
  }

  const getCryptoCurrencyById = useCallback(
    (id: string): CryptoCurrency | undefined => {
      const currency = filteredCryptos?.find((element) => element.id.toLowerCase() === id.toLowerCase());
      return currency;
    },
    [filteredCryptos],
  );

  const getFiatCurrencyById = useCallback(
    (id: string): FiatCurrency | undefined => {
      const currency = filteredFiats?.find((element) => element.id.toLowerCase() === id.toLowerCase());
      return currency;
    },
    [filteredFiats],
  );

  const getFirstFiat = useCallback((): FiatCurrency | null => filteredFiats?.[0] || null, [filteredFiats]);

  const getFirstCrypto = useCallback((): CryptoCurrency | null => filteredCryptos?.[0] || null, [filteredCryptos]);

  return {
    currencies: {
      cryptoCurrencies: filteredCryptos ?? [],
      fiatCurrencies: filteredFiats ?? [],
    },
    isLoading,
    isError,
    getCryptoCurrencyById,
    getFiatCurrencyById,
    getFirstFiat,
    getFirstCrypto,
    error,
  };
};
