export type NavigationContextProps = {
  navigation: NavigationStateType;
  onlyScreen: (screen: ScreenType) => void;
  backScreen: () => void;
  backScreens: (numberOfScreens: number) => void;
  firstScreen: () => void;
  nextScreen: (screen: ScreenType) => void;
  replaceScreen: (screen: ScreenType) => void;
  currentStep: () => number;
  currentScreenKey: () => string;
};

export type ScreenType = React.ReactNode;

export enum NavigationActionType {
  Only = "ONLY",
  Push = "PUSH",
  Pop = "POP",
  Replace = "REPLACE",
  First = "FIRST",
  BackScreens = "BACK_SCREENS",
}

export type NavigationStateType = {
  screens: ScreenType[];
  currentSes: number;
};

export type NavigationActions =
  | {
      type: NavigationActionType.Only;
      screen: ScreenType;
    }
  | {
      type: NavigationActionType.Push;
      screen: ScreenType;
    }
  | {
      type: NavigationActionType.Pop;
    }
  | {
      type: NavigationActionType.First;
    }
  | {
      type: NavigationActionType.Replace;
      screen: ScreenType;
    }
  | {
      type: NavigationActionType.BackScreens;
      numberOfScreens: number;
    };
