import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useCurrencies } from "../../../hooks/useCurrencies";
import { useOnramps } from "../../../hooks/useOnramps";
import { usePaymentMethods } from "../../../hooks/usePaymentTypes";
import { useUpdateQuote } from "../../../hooks/useUpdateQuote";
import { onrampMetadata } from "../../../metadata/onrampMetadata";
import {
  useNavigationContext,
  useTransactionContext,
} from "../../../providers";
import { useParamContext } from "../../../providers/ParamContextProvider/ParamContextProvider";
import { Onramp } from "../../../types";
import ErrorView from "../ErrorView";
import LoadingView from "../LoadingView";
import Steps from "../Steps";
import { useLanguage } from "../../../hooks/useLanguage";
import { useDefaults } from "../../../hooks/useDefaults";

const SkipTransactionScreenView: FC = () => {
  const { formatMessage } = useLanguage();
  const { params } = useParamContext();
  const { refetch } = useUpdateQuote();
  const {
    transaction,
    setSelectedFiat,
    setSelectedCrypto,
    setSelectedOnramp,
    setSelectedPaymentMethod,
    setFiatAmount,
    setCryptoAmount,
    setTransactionType,
  } = useTransactionContext();

  const debouncedUpdateQuote = useDebouncedCallback(refetch, 600);

  const { nextScreen } = useNavigationContext();

  const { onramps } = useOnramps();
  const {
    paymentMethods,
    refetch: fetchPaymentMethods,
    getRecommendedPaymentMethod,
    isError: isPaymentError,
  } = usePaymentMethods();
  const {
    isLoading: isCurrenciesLoading,
    getFiatCurrencyById,
    getCryptoCurrencyById,
  } = useCurrencies();
  const {
    selectedFiat,
    selectedCrypto,
    selectedOnramp,
    selectedPaymentMethod,
    fiatAmount,
    cryptoAmount,
    selectedCountry,
  } = transaction;
  const { country } = params;
  const {
    txnType,
    txnFiat,
    txnCrypto,
    txnAmount,
    txnOnramp,
    txnOfframp,
    txnPaymentMethod,
    txnInput,
  } = params;
  const [error, setError] = useState<string>("");
  const { refetch: fetchDefaults } = useDefaults(
    country ?? selectedCountry?.countryCode.toLowerCase(),
    txnType ?? "buy"
  );

  useEffect(() => {
    fetchDefaults();
  }, [fetchDefaults, selectedCountry]);

  const txnRamp = useMemo(() => {
    return txnType === "sell" ? txnOfframp : txnOnramp;
  }, [txnType, txnOfframp, txnOnramp]);

  useEffect(() => {
    if (error) {
      nextScreen(
        <ErrorView
          errorType="Checkout"
          title={formatMessage("skipTransactionScreenView.errorView.message")}
          description={error}
        />
      );
    }
  }, [error, formatMessage, nextScreen]);

  useEffect(() => {
    setTransactionType(txnType ?? "buy");
  }, [setTransactionType]);

  useEffect(() => {
    if (isPaymentError)
      setError(
        formatMessage(
          "skipTransactionScreenView.errorView.missingPaymentMethod"
        )
      );
  }, [isPaymentError]);

  useEffect(() => {
    if (!(txnFiat && txnCrypto && txnAmount && txnAmount > 0)) {
      setError(
        formatMessage("skipTransactionScreenView.errorView.missingParams")
      );
    }
  }, [txnFiat, txnCrypto, txnAmount, formatMessage]);

  useEffect(() => {
    if (txnFiat && isCurrenciesLoading !== true) {
      const fiat = getFiatCurrencyById(txnFiat);
      if (fiat) {
        setSelectedFiat(fiat);
      } else {
        setError(
          formatMessage("skipTransactionScreenView.errorView.missingFiat")
        );
      }
    }
  }, [
    formatMessage,
    getFiatCurrencyById,
    isCurrenciesLoading,
    nextScreen,
    setSelectedFiat,
    txnFiat,
  ]);

  useEffect(() => {
    if (txnCrypto && isCurrenciesLoading !== true) {
      const crypto = getCryptoCurrencyById(txnCrypto);
      if (crypto) {
        setSelectedCrypto(crypto);
      } else {
        formatMessage("skipTransactionScreenView.errorView.missingCrypto");
      }
    }
  }, [
    getCryptoCurrencyById,
    isCurrenciesLoading,
    setSelectedCrypto,
    txnCrypto,
  ]);

  useEffect(() => {
    if (txnType === "buy" && txnAmount && txnAmount > 0) {
      if (txnInput === "source") setFiatAmount(txnAmount);
      else setCryptoAmount(txnAmount);
    } else if (txnType === "sell" && txnAmount && txnAmount > 0) {
      setCryptoAmount(txnAmount);
    }
  }, [setCryptoAmount, setFiatAmount, txnAmount, txnInput, txnType]);

  useEffect(() => {
    if (txnType === "buy" && selectedFiat?.id) {
      fetchPaymentMethods();
    }
  }, [fetchPaymentMethods, selectedFiat?.id]);

  useEffect(() => {
    if (txnType === "sell" && selectedCrypto?.id) {
      fetchPaymentMethods();
    }
  }, [fetchPaymentMethods, selectedCrypto?.id]);

  useEffect(() => {
    if (txnRamp) {
      const onramp = onrampMetadata[txnRamp];
      if (!onramp) {
        setError(
          formatMessage("skipTransactionScreenView.errorView.missingProvider")
        );
      }
    }
  }, [formatMessage, setSelectedOnramp, txnRamp]);

  useEffect(() => {
    const targetAmount = selectedOnramp?.payout ?? 0;
    if (txnType === "buy") {
      if (txnInput === "source") setCryptoAmount(targetAmount);
      else setFiatAmount(targetAmount);
    } else if (txnType === "sell") {
      setFiatAmount(targetAmount);
    }
  }, [selectedOnramp, setCryptoAmount, setFiatAmount, txnInput, txnType]);

  useEffect(() => {
    if (paymentMethods.length > 0) {
      let paymentMethodId = txnPaymentMethod;
      if (!txnPaymentMethod) {
        paymentMethodId = getRecommendedPaymentMethod(paymentMethods);
      }
      const paymentMethod = paymentMethods.find(
        (p) => paymentMethodId === p.paymentTypeId
      );

      if (paymentMethod && selectedFiat?.id) {
        setSelectedPaymentMethod(paymentMethod);
      } else {
        setError(
          formatMessage(
            "skipTransactionScreenView.errorView.missingPaymentMethod"
          )
        );
      }
    }
  }, [
    formatMessage,
    getRecommendedPaymentMethod,
    paymentMethods,
    selectedFiat?.id,
    setSelectedPaymentMethod,
    txnPaymentMethod,
  ]);

  useEffect(() => {
    if (
      error === "" &&
      selectedCrypto?.id &&
      selectedFiat?.code &&
      selectedPaymentMethod?.name &&
      (((txnInput === "source" || txnType === "buy") && fiatAmount > 0) ||
        ((txnInput === "destination" || txnType === "sell") &&
          cryptoAmount > 0))
    ) {
      debouncedUpdateQuote();
    }
  }, [
    cryptoAmount,
    debouncedUpdateQuote,
    error,
    fiatAmount,
    selectedCrypto?.id,
    selectedFiat?.code,
    selectedPaymentMethod?.name,
    txnInput,
  ]);

  const findBestOnramp = useCallback(
    (availableOnramps: Onramp[]) => {
      const firstOnrampWithoutErrors = availableOnramps.find(
        (o) => !o.errors && !o.isMock
      );
      if (firstOnrampWithoutErrors) {
        // Getting the 1st onramp beacause onramps are sorted by price. This will be changed once the recommendation engine introduced
        setSelectedOnramp(firstOnrampWithoutErrors);
      } else {
        const onrampsWithErrors = availableOnramps.filter((o) => o.errors);
        const onrampWithLimitError = onrampsWithErrors.find((o) =>
          o.errors?.some((e) => e.type === "LimitMismatch")
        );

        if (onrampWithLimitError) {
          setError(onrampWithLimitError.errors?.[0]?.message as string);
        } else {
          setSelectedOnramp(null);
          setError(
            formatMessage(
              "skipTransactionScreenView.errorView.unableToFindOnramp"
            )
          );
        }
      }
    },
    [formatMessage, setSelectedOnramp]
  );

  useEffect(() => {
    if (onramps.length > 0) {
      const availableOnramps = onramps.filter((onramp) => onramp.isAvailable);

      if (txnRamp) {
        const suppliedOnramp = onramps.find(
          (o) => txnRamp.toLowerCase() === o.id.toLowerCase()
        );
        if (suppliedOnramp && !suppliedOnramp.errors) {
          setSelectedOnramp(suppliedOnramp);
        } else {
          setError(suppliedOnramp?.errors?.[0].message as string);
        }
      } else {
        findBestOnramp(availableOnramps);
      }
    }
  }, [findBestOnramp, onramps, setSelectedOnramp, txnRamp]);

  useEffect(() => {
    if (
      error === "" &&
      selectedCrypto?.id &&
      selectedFiat?.code &&
      selectedPaymentMethod?.name &&
      selectedOnramp?.id
    ) {
      nextScreen(<Steps key="Steps" />);
    }
  }, [
    error,
    nextScreen,
    selectedCrypto?.id,
    selectedFiat?.code,
    selectedOnramp?.id,
    selectedPaymentMethod?.name,
  ]);

  return <LoadingView />;
};

export default SkipTransactionScreenView;
