import { useIndexedDbContext } from "../providers";
import { useAuthContext } from "../providers/AuthContextProvider";

export const useLogout = () => {
  const { removeAuthToken, removeOnramperId } = useIndexedDbContext();
  const { setIsAuthenticated, setEmail, setOnramperId } = useAuthContext();

  const logout = () => {
    setIsAuthenticated(false);
    setEmail(null);
    setOnramperId(null);
    removeAuthToken();
    removeOnramperId();
  };

  return {
    logout,
  };
};
