export const DEV_BASE_URL = "https://api-dev.onramper.com";
export const STAG_BASE_URL = "https://api-stg.onramper.com";
export const PROD_BASE_URL = "https://api.onramper.com";
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const supported = `${BASE_URL}/supported`;
export const currencies = `${BASE_URL}/currencies`;
export const checkoutIntent = `${BASE_URL}/checkout/intent`;
export const defaultValues = `${BASE_URL}/supported/defaults`;
export const supportedCountries = `${BASE_URL}/supported/countries`;
export const quotes = `${BASE_URL}/quotes`;
export const paymentTypes = `${BASE_URL}/supported/payment-types`;
export const recommendations = `${BASE_URL}/recommendations`;
export const swapOrderCreate = `${BASE_URL}/transactions/swap`;
export const signatureVerification = `${BASE_URL}/checkout/signature-verification`;
export const exodusQuotesURL = "https://exchange.exodus.io/v3/pairs";
export const swapOrderStatus = `${BASE_URL}/transactions/swap`;
export const signin = `${BASE_URL}/login`;
export const verifyOtp = `${BASE_URL}/verify-otp`;
export const verifyJwt = `${BASE_URL}/verify-jwt`;
export const logout = `${BASE_URL}/logout`;
export const refresh = `${BASE_URL}/refresh`;
export const integrations = `${BASE_URL}/supported/integrations`;
export const meshLinkToken = `${BASE_URL}/mesh/link-token`;
export const meshAccessTokens = `${BASE_URL}/mesh/access-tokens`;
