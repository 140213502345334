import React from 'react';
import styles from './OverlayHeader.module.css';
import commonStyles from '../../../../common-styles.module.css';
import { ReactComponent as CloseIcon } from '../../../../icons/close.svg';
import { OverlayHeaderProps } from './OverlayHeader.models';

const OverlayHeader: React.FC<OverlayHeaderProps> = (props: OverlayHeaderProps) => {
  const { onClose, text, closeButton } = props;
  return (
    <div className={`${styles['overlay-header']} ${commonStyles['flex-all']}`}>
      <div className={styles.text}> {text} </div>
      {closeButton && (
        <div className={styles['overlay-header-close-icon']} onClick={onClose}>
          <CloseIcon />
        </div>
      )}
    </div>
  );
};

export default OverlayHeader;
