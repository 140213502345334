import { Button } from '@onramper/oui';
import React, { FC } from 'react';
import { useNavigationContext } from '../../../providers';
import BottomOverlay from '../BottomOverlay/BottomOverlay';
import styles from './AlertView.module.css';
import { AlertViewProps } from './AlertViewProps';
import { useLanguage } from '../../../hooks/useLanguage';

const AlertView: FC<AlertViewProps> = (props: AlertViewProps) => {
  const { title, description, icon, actionButton, secondaryActionButton, renderActionButtonOnly = false } = props;
  const { backScreen } = useNavigationContext();
  const { formatMessage } = useLanguage();


  return (
    <BottomOverlay maxHeight="50%">
      <div className={styles['alert-content']}>
        <div className={styles['alert-top-content']}>
          <div className={styles['alert-icon']}>{icon}</div>
          <div className={styles['alert-title']}>{title}</div>
          <div className={styles['alert-description']}>{description}</div>
        </div>
        <div className={styles['alert-bottom-content']}>
          {!renderActionButtonOnly && (secondaryActionButton
            ? secondaryActionButton
            :
            <Button
              label={formatMessage('alertView.dismissButton.label')}
              size="large-rectangle"
              variant="secondary"
              expanded
              onClick={() => backScreen()}
              className={styles['dismiss-button']}
            />)}


          {actionButton}
        </div>
      </div>
    </BottomOverlay>
  );
};
export default AlertView;
