import React, { createContext, FC, useCallback, useContext, useMemo, useReducer } from 'react';

import { initialState } from './initialState';
import { swapReducer } from './reducers';
import { Provider, Quotes, SwapActionType, SwapContextProps, SwapError } from './types';
import { ChildrenProps, CryptoCurrency } from '../../types';

const initialContext: SwapContextProps = {
  swapTransaction: initialState,
  setSourceAmount: () => null,
  setTargetAmount: () => null,
  setSelectedSource: () => null,
  setSelectedTarget: () => null,
  setSelectedProvider: () => null,
  setSourceWalletAddress: () => null,
  setSourceWalletAddressTag: () => null,
  setTargetWalletAddress: () => null,
  setTargetWalletAddressTag: () => null,
  setTransactionHash: () => null,
  setTxId: () => null,
  setQuotes: () => null,
  resetSwap: () => null,
  setSwapError: () => null,
};

const SwapContext = createContext<SwapContextProps>(initialContext);

export const useSwapContext = () => {
  const context = useContext(SwapContext);
  if (context === undefined) {
    throw new Error('useSwapContext must be used within a SwapContextProvider');
  }
  return context;
};

export const SwapContextProvider: FC<ChildrenProps> = (props: ChildrenProps) => {
  const [swapTransaction, dispatch] = useReducer(swapReducer, initialState);

  const setSelectedSource = useCallback(
    (currency: CryptoCurrency | null) => {
      dispatch({
        type: SwapActionType.SetSelectedSource,
        payload: currency,
      });
    },
    [dispatch],
  );

  const setSelectedTarget = useCallback(
    (currency: CryptoCurrency | null) => {
      dispatch({
        type: SwapActionType.SetSelectedTarget,
        payload: currency,
      });
    },
    [dispatch],
  );

  const setSourceAmount = useCallback(
    (amount: number) => {
      dispatch({
        type: SwapActionType.SetSourceAmount,
        payload: amount,
      });
    },
    [dispatch],
  );

  const setTargetAmount = useCallback(
    (amount: number) => {
      dispatch({
        type: SwapActionType.SetTargetAmount,
        payload: amount,
      });
    },
    [dispatch],
  );

  const setSelectedProvider = useCallback(
    (onramp: Provider | null) => {
      dispatch({
        type: SwapActionType.SetSelectedProvider,
        payload: onramp,
      });
    },
    [dispatch],
  );

  const setQuotes = useCallback(
    (quotes: Quotes | null) => {
      dispatch({
        type: SwapActionType.SetQuotes,
        payload: quotes,
      });
    },
    [dispatch],
  );

  const resetSwap = useCallback(() => {
    dispatch({
      type: SwapActionType.ResetSwap,
      payload: null,
    });
  }, [dispatch]);

  const setSwapError = useCallback(
    (error: SwapError | null) => {
      dispatch({
        type: SwapActionType.SetSwapError,
        payload: error,
      });
    },
    [dispatch],
  );

  const setSourceWalletAddress = useCallback(
    (address: string | null) => {
      dispatch({
        type: SwapActionType.SetSourceWalletAddress,
        payload: address,
      });
    },
    [dispatch],
  );

  const setSourceWalletAddressTag = useCallback(
    (tag: string | null) => {
      dispatch({
        type: SwapActionType.SetSourceWalletAddressTag,
        payload: tag,
      });
    },
    [dispatch],
  );

  const setTargetWalletAddress = useCallback(
    (address: string | null) => {
      dispatch({
        type: SwapActionType.SetTargetWalletAddress,
        payload: address,
      });
    },
    [dispatch],
  );

  const setTargetWalletAddressTag = useCallback(
    (tag: string | null) => {
      dispatch({
        type: SwapActionType.SetTargetWalletAddressTag,
        payload: tag,
      });
    },
    [dispatch],
  );

  const setTransactionHash = useCallback(
    (hash: string | null) => {
      dispatch({
        type: SwapActionType.SetTransactionHash,
        payload: hash,
      });
    },
    [dispatch],
  );

  const setTxId = useCallback(
    (txId: string | null) => {
      dispatch({
        type: SwapActionType.SetTxId,
        payload: txId,
      });
    },
    [dispatch],
  );

  const value = useMemo(
    () => ({
      swapTransaction,
      setSourceAmount,
      setTargetAmount,
      setSelectedSource,
      setSelectedTarget,
      setSourceWalletAddress,
      setSourceWalletAddressTag,
      setTargetWalletAddress,
      setTargetWalletAddressTag,
      setSelectedProvider,
      setTransactionHash,
      setTxId,
      setQuotes,
      resetSwap,
      setSwapError,
    }),
    [
      resetSwap,
      setQuotes,
      setSelectedProvider,
      setSelectedSource,
      setSelectedTarget,
      setSourceAmount,
      setSourceWalletAddress,
      setSourceWalletAddressTag,
      setSwapError,
      setTargetAmount,
      setTargetWalletAddress,
      setTargetWalletAddressTag,
      setTransactionHash,
      setTxId,
      swapTransaction,
    ],
  );

  return <SwapContext.Provider value={value}>{props.children}</SwapContext.Provider>;
};
