import { createWeb3Modal } from "@web3modal/wagmi/react";

import { WagmiProvider, createConfig, http } from "wagmi";
import {
  arbitrum,
  bsc,
  fantom,
  mainnet,
  optimism,
  sepolia,
} from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { ChildrenProps } from "../../types";
import { coinbaseWallet, injected, walletConnect } from "wagmi/connectors";

const queryClient = new QueryClient();

export function WalletConnectProvider({ children }: ChildrenProps) {
  const projectId = "136ae9adaf080ea6b58ce718cc963768";

  // 2. Create wagmiConfig
  const metadata = {
    name: "Onramper",
    description:
      "Put 15+ onramps (and over 130+ payment methods!) right in your app for truly global coverage  — with just eight lines of code.",
    url: "https://onramper.com",
    icons: ["https://avatars.githubusercontent.com/u/37784886"],
  };

  const config = createConfig({
    chains: [mainnet, arbitrum, fantom, optimism, bsc, sepolia],

    transports: {
      [mainnet.id]: http(),
      [arbitrum.id]: http(),
      [fantom.id]: http(),
      [optimism.id]: http(),
      [bsc.id]: http(),
      [sepolia.id]: http(),
    },
    connectors: [
      walletConnect({ projectId, metadata, showQrModal: true }),
      injected({ shimDisconnect: true }),
      coinbaseWallet({
        appName: metadata.name,
        appLogoUrl: metadata.icons[0],
      }),
    ],
  });

  // 3. Create modal
  createWeb3Modal({
    wagmiConfig: config,
    projectId,
  });

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}
