import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";
import {
  ChildrenProps,
  CryptoCurrency,
  FiatCurrency,
  Onramp,
  PaymentMethod,
  Quotes,
  RecurringPaymentMetadata,
  SupportedCountries,
  TransactionError,
  TransactionType,
} from "../../types";
import { initialState } from "./initialState";
import { transactionReducer } from "./reducers";
import { TransactionActionType, TransactionContextProps } from "./types";

const initialContext: TransactionContextProps = {
  transaction: initialState,
  setFiatAmount: () => null,
  setCryptoAmount: () => null,
  setSelectedFiat: () => null,
  setSelectedCrypto: () => null,
  setSelectedOnramp: () => null,
  setSelectedPaymentMethod: () => null,
  setSelectedLinkIntegration: () => null,
  setQuotes: () => null,
  setSelectedCountry: () => null,
  setRecurringPayment: () => null,
  setRecurringPaymentMetadata: () => null,
  setTransactionType: () => null,
  resetTransaction: () => null,
  setTransactionError: () => null,
  setOtcTxn: () => null,
};

const TransactionContext =
  createContext<TransactionContextProps>(initialContext);

export const useTransactionContext = () => {
  const context = useContext(TransactionContext);
  if (context === undefined) {
    throw new Error(
      "useTransactionContext must be used within a TransactionContextProvider"
    );
  }
  return context;
};

export const TransactionContextProvider: FC<ChildrenProps> = (
  props: ChildrenProps
) => {
  const [transaction, dispatch] = useReducer(transactionReducer, initialState);

  const setSelectedFiat = useCallback(
    (currency: FiatCurrency | null) => {
      dispatch({
        type: TransactionActionType.SetSelectedFiat,
        payload: currency,
      });
    },
    [dispatch]
  );

  const setSelectedCrypto = useCallback(
    (currency: CryptoCurrency | null) => {
      dispatch({
        type: TransactionActionType.SetSelectedCrypto,
        payload: currency,
      });
    },
    [dispatch]
  );

  const setFiatAmount = useCallback(
    (amount: number) => {
      dispatch({
        type: TransactionActionType.SetFiatAmount,
        payload: amount,
      });
    },
    [dispatch]
  );

  const setCryptoAmount = useCallback(
    (amount: number) => {
      dispatch({
        type: TransactionActionType.SetCryptoAmount,
        payload: amount,
      });
    },
    [dispatch]
  );

  const setSelectedOnramp = useCallback(
    (onramp: Onramp | null) => {
      dispatch({
        type: TransactionActionType.SetSelectedOnramp,
        payload: onramp,
      });
    },
    [dispatch]
  );

  const setSelectedPaymentMethod = useCallback(
    (paymentMethod: PaymentMethod | null) => {
      dispatch({
        type: TransactionActionType.SetSelectedPaymentMethod,
        payload: paymentMethod,
      });
    },
    [dispatch]
  );

  const setQuotes = useCallback(
    (quotes: Quotes | null) => {
      dispatch({
        type: TransactionActionType.SetQuotes,
        payload: quotes,
      });
    },
    [dispatch]
  );

  const setSelectedCountry = useCallback(
    (country: SupportedCountries) => {
      dispatch({
        type: TransactionActionType.SetSelectedCountry,
        payload: country,
      });
    },
    [dispatch]
  );
  const setTransactionType = useCallback(
    (transactionType: TransactionType | null) => {
      dispatch({
        type: TransactionActionType.SetTransactionType,
        payload: transactionType,
      });
    },
    [dispatch]
  );

  const setRecurringPayment = useCallback(
    (isRecurringPayment: boolean) => {
      dispatch({
        type: TransactionActionType.SetRecurringPayment,
        payload: isRecurringPayment,
      });
    },
    [dispatch]
  );
  const setRecurringPaymentMetadata = useCallback(
    (recurringPaymentMetadata: RecurringPaymentMetadata) => {
      dispatch({
        type: TransactionActionType.SetRecurringPaymentMetadata,
        payload: recurringPaymentMetadata,
      });
    },
    [dispatch]
  );

  const resetTransaction = useCallback(() => {
    dispatch({
      type: TransactionActionType.ResetTransaction,
      payload: null,
    });
  }, [dispatch]);

  const setTransactionError = useCallback(
    (error: TransactionError | null) => {
      dispatch({
        type: TransactionActionType.SetTransactionError,
        payload: error,
      });
    },
    [dispatch]
  );

  const setOtcTxn = useCallback(
    (isOtcTxn: boolean) => {
      dispatch({
        type: TransactionActionType.SetOtcTxn,
        payload: isOtcTxn,
      });
    },
    [dispatch]
  );

  const setSelectedLinkIntegration = useCallback(
    (linkIntegration: string | null) => {
      dispatch({
        type: TransactionActionType.SetSelectedLinkIntegration,
        payload: linkIntegration,
      });
    },
    [dispatch]
  );

  const value = useMemo(
    () => ({
      transaction,
      setFiatAmount,
      setCryptoAmount,
      setSelectedFiat,
      setSelectedCrypto,
      setSelectedOnramp,
      setSelectedPaymentMethod,
      setSelectedLinkIntegration,
      setQuotes,
      setSelectedCountry,
      setRecurringPayment,
      setRecurringPaymentMetadata,
      setTransactionType,
      resetTransaction,
      setTransactionError,
      setOtcTxn,
    }),
    [
      transaction,
      setFiatAmount,
      setCryptoAmount,
      setSelectedFiat,
      setSelectedCrypto,
      setSelectedOnramp,
      setSelectedPaymentMethod,
      setSelectedLinkIntegration,
      setQuotes,
      setSelectedCountry,
      setRecurringPayment,
      setRecurringPaymentMetadata,
      setTransactionType,
      resetTransaction,
      setTransactionError,
      setOtcTxn,
    ]
  );

  return (
    <TransactionContext.Provider value={value}>
      {props.children}
    </TransactionContext.Provider>
  );
};
