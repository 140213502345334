import React, { ErrorInfo } from 'react';
import { ChildrenProps } from '../../../types';
import { datadogRum } from '@datadog/browser-rum';

type ErrorBoundaryProps = {
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
  fallBackComponent?: React.ReactNode;
} & ChildrenProps;

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { onError } = this.props;
    if (onError) {
      onError(error, errorInfo);
    }
    // Pushing error data to Datadog
    datadogRum.addError(error);
  }

  render() {
    const { hasError } = this.state;
    const { children, fallBackComponent } = this.props;

    if (hasError) {
      return fallBackComponent;
    }

    return children;
  }
}

export default ErrorBoundary;
