import { TabHeader } from "@onramper/oui";
import React, { FC, useEffect, useState, Suspense, lazy } from "react";
import commonStyles from "../../../common-styles.module.css";
import { useThirdPartyCookieCheck } from "../../../hooks/useThirdPartyCookieCheck";
import { useParamContext, useTransactionContext } from "../../../providers";
import { useNavigationContext } from "../../../providers/NavigationContextProvider";
import { WidgetNotification } from "../../common/WidgetNotification/WidgetNotification";
import OverlayMenu from "../MenuOverlayView/MenuOverlayView";
import { useLanguage } from "../../../hooks/useLanguage";
import { TabName, TransactionType } from "../../../types";
import LoadingView from "../LoadingView";
import SessionTimedoutView from "../SessionTimedoutView/SessionTimedoutView";
import { useAuthContext } from "../../../providers/AuthContextProvider";
import Branding from "../../common/Branding";

const BuyCryptoView = lazy(
  () => import("../BaseTransactionView/BuyCryptoView")
);
const SellCryptoView = lazy(
  () => import("../BaseTransactionView/SellCryptoView")
);
const SwapCryptoView = lazy(
  () => import("../BaseTransactionView/SwapCryptoView")
);

export const DEFAULT_TAB_ORDERING: TabName[] = ["buy", "sell", "swap"];

type ContainerViewProps = {
  selectedTabId?: number;
};

const ContainerView: FC<ContainerViewProps> = ({
  selectedTabId,
}: ContainerViewProps) => {
  const { formatMessage } = useLanguage();
  const { nextScreen } = useNavigationContext();
  const { setTransactionType, resetTransaction } = useTransactionContext();
  const {
    params: {
      supportSell,
      supportBuy,
      supportSwap,
      requestedModes,
      enableAuth,
      hideHeader,
    },
  } = useParamContext();
  // const { signature } = buy;
  const [selectedTab, setSelectedTab] = useState<number>(selectedTabId ?? 0);

  useThirdPartyCookieCheck();
  // const { validateSignature, isSignatureValError } = useValidateSignature();
  const { auth } = useAuthContext();

  const tabClickHandler = (index: number) => {
    resetTransaction();
    setSelectedTab(index);
  };
  const getOrderedTabs = (): TabName[] => {
    const tabOrder = requestedModes;
    //in case mode is not specified in props, we default to the default tab ordering and default enabled modes
    if (
      !tabOrder ||
      (!tabOrder.length && (supportBuy || supportSell || supportSwap))
    ) {
      return DEFAULT_TAB_ORDERING.filter(
        (tab) =>
          (tab === "buy" && supportBuy) ||
          (tab === "sell" && supportSell) ||
          (tab === "swap" && supportSwap)
      );
    }
    return tabOrder.map((tab) => tab as TabName);
  };
  const getTabConfig = () => {
    const tabOrder = getOrderedTabs();
    const tabDefinitions: Record<
      TabName,
      {
        flag: boolean | undefined;
        name: string;
        view: JSX.Element;
        transactionType?: TransactionType;
      }
    > = {
      buy: {
        flag: supportBuy,
        name: formatMessage("containerView.header.buy"),
        view: (
          <Suspense fallback={<LoadingView />}>
            <BuyCryptoView />
          </Suspense>
        ),
        transactionType: "buy",
      },
      sell: {
        flag: supportSell,
        name: formatMessage("containerView.header.sell"),
        view: (
          <Suspense fallback={<LoadingView />}>
            <SellCryptoView />
          </Suspense>
        ),
        transactionType: "sell",
      },
      swap: {
        flag: supportSwap,
        name: formatMessage("containerView.header.swap"),
        view: (
          <Suspense fallback={<LoadingView />}>
            <SwapCryptoView />
          </Suspense>
        ),
      },
    };
    const tabConfig = tabOrder
      .map((tabName) => tabDefinitions[tabName])
      .filter((tab) => tab.flag);
    return tabConfig;
  };

  useEffect(() => {
    if (enableAuth === true && auth.authError === "FailedToRefreshToken")
      nextScreen(<SessionTimedoutView />);
  }, [auth.authError]);

  useEffect(() => {
    const txnType =
      (getTabConfig()[selectedTab]?.transactionType as TransactionType) ??
      "buy";
    setTransactionType(txnType);
  }, [selectedTab, setTransactionType, requestedModes]);

  const activeTabs = getTabConfig();
  const renderView = () => activeTabs[selectedTab]?.view;

  return (
    <div
      style={{
        gap: "12px",
        padding: "16px",
        boxSizing: "border-box",
      }} // TODO: move to css
      className={commonStyles.view}
    >
      <TabHeader
        tabs={activeTabs.map((tab) => tab.name)}
        onClickItem={tabClickHandler}
        tabSelected={selectedTab}
        logo={<Branding type="rounded" />}
        onMenuClick={() => nextScreen(<OverlayMenu />)}
        displayHeaderText={activeTabs.length === 1}
        hideHeader={activeTabs.length === 1 && hideHeader}
        headerText={`${activeTabs[0]?.name} crypto`}
      />
      <WidgetNotification />
      {renderView()}
      <div className={commonStyles["container-footer"]}>
        <Branding type="full" />
      </div>
    </div>
  );
};

export default ContainerView;
