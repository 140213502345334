import React, { FC } from "react";
import { Button } from "@onramper/oui";
import { ReactComponent as Danger } from "../../../icons/danger-yellow.svg";
import { useLanguage } from "../../../hooks/useLanguage";
import AlertView from "../AlertView";
import styles from "./SessionTimedoutView.module.css";
import { useNavigationContext } from "../../../providers";
import ContainerView from "../ContainerView";
import { useAuthContext } from "../../../providers/AuthContextProvider";
import { SignIn } from "../SignInView";

const SessionTimedoutView: FC = () => {
  const { formatMessage } = useLanguage();
  const { onlyScreen, nextScreen } = useNavigationContext();
  const { setAuthError } = useAuthContext();
  return (
    <AlertView
      title={formatMessage("sessionTimedoutView.alert.title")}
      description={formatMessage("sessionTimedoutView.alert.description")}
      icon={<Danger />}
      actionButton={
        <Button
          label={formatMessage("sessionTimedoutView.button.signIn")}
          size="large-rectangle"
          expanded
          onClick={() => {
            setAuthError("None");
            nextScreen(<SignIn signInFrom="session-timedout" />);
          }}
          className={styles["transaction-action-button"]}
        />
      }
      secondaryActionButton={
        <Button
          label={formatMessage("sessionTimedoutView.button.continue")}
          size="large-rectangle"
          expanded
          onClick={() => {
            setAuthError("None");
            onlyScreen(<ContainerView />);
          }}
          className={styles["transaction-action-secondary-button"]}
        />
      }
    />
  );
};

export default SessionTimedoutView;
