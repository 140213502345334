import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ChildrenProps } from "../../types";
import indexedDbApi from "../../utils/indexedDbApi";

const IndexedDbContext = createContext<{
  isLoading: boolean;
  authToken: string;
  setAuthToken: (token: string) => Promise<void>;
  removeAuthToken: () => Promise<void>;
  onramperId: string;
  setOnramperId: (id: string) => Promise<void>;
  removeOnramperId: () => Promise<void>;
} | null>(null);

export const useIndexedDbContext = () => {
  const context = useContext(IndexedDbContext);
  if (!context) {
    throw new Error(
      "useIndexedDbContext must be used within an IndexedDbProvider"
    );
  }
  return context;
};

export const IndexedDbProvider: FC<ChildrenProps> = (props: ChildrenProps) => {
  const [authToken, setToken] = useState<string>("");
  const [onramperId, setOnramperId] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const broadcastChannel = new BroadcastChannel("indexeddb_changes");

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsLoading(true);
        const [storedToken, storedOnramperId] = await Promise.all([
          indexedDbApi.getAuthToken(),
          indexedDbApi.getOnramperId(),
        ]);
        if (storedToken) setToken(storedToken);
        if (storedOnramperId) setOnramperId(storedOnramperId);
      } catch (error) {
        console.error("Failed to fetch initial data from IndexedDB:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchInitialData();

    const handleBroadcast = (event: any) => {
      setToken(event.data.token);
    };

    broadcastChannel.addEventListener("message", handleBroadcast);

    return () => {
      broadcastChannel.removeEventListener("message", handleBroadcast);
    };
  }, []);

  const setAuthToken = useCallback(async (token: string) => {
    await indexedDbApi.setAuthToken(token);
    setToken(token);
  }, []);

  const setOnramperIdValue = useCallback(async (id: string) => {
    await indexedDbApi.setOnramperId(id);
    setOnramperId(id);
  }, []);

  const removeAuthToken = useCallback(async () => {
    await indexedDbApi.removeAuthToken();
    setToken("");
  }, []);

  const removeOnramperIdValue = useCallback(async () => {
    await indexedDbApi.removeOnramperId();
    setOnramperId("");
  }, []);

  const value = useMemo(
    () => ({
      isLoading,
      onramperId,
      authToken,
      setAuthToken,
      removeAuthToken,
      setOnramperId: setOnramperIdValue,
      removeOnramperId: removeOnramperIdValue,
    }),
    [
      isLoading,
      onramperId,
      authToken,
      setAuthToken,
      removeAuthToken,
      setOnramperIdValue,
      removeOnramperIdValue,
    ]
  );

  return (
    <IndexedDbContext.Provider value={value}>
      {props.children}
    </IndexedDbContext.Provider>
  );
};
