import { Auth } from "../../types";
import { AuthActions, AuthActionType } from "./types";

export const authReducer = (state: Auth, action: AuthActions) => {
  switch (action.type) {
    case AuthActionType.SetIsAuthenticated:
      return { ...state, isAuthenticated: action.payload };
    case AuthActionType.SetAuthToken:
      return { ...state, authToken: action.payload };
    case AuthActionType.SetOnramperId:
      return { ...state, onramperId: action.payload };
    case AuthActionType.SetEmail:
      return { ...state, email: action.payload };
    case AuthActionType.SetOtp:
      return { ...state, otp: action.payload };
    case AuthActionType.SetAuthError:
      return { ...state, authError: action.payload };
    default:
      throw new Error("Unhandled action type");
  }
};
