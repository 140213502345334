import { Icon, ListItem } from '@onramper/oui';
import React, { FC, useCallback, useState } from 'react';

import { ReactComponent as CheckMark } from '../../../../icons/check-mark.svg';
import { useTransactionContext } from '../../../../providers';
import { SupportedCountries } from '../../../../types';
import { isSearchResultFound } from '../../../../utils/commonUtils';
import BottomOverlay from '../../../views/BottomOverlay/BottomOverlay';
import SelectionView from '../../../views/SelectionView/SelectionView';

export type CountryPickerProps = {
  title: string;
  countries: SupportedCountries[];
  onSelectCurrency: (item: SupportedCountries, selectedIndex?: number) => void;
};

const CountryPicker: FC<CountryPickerProps> = (props: CountryPickerProps) => {
  const { title, countries, onSelectCurrency } = props;
  const [searchedItems, setSearchedItems] = useState<SupportedCountries[] | undefined>();
  const {
    transaction: { selectedCountry },
  } = useTransactionContext();

  const countrySearchHandler = useCallback(
    (searchKey: string) => {
      const searchResults = [...countries].filter(
        (item) => isSearchResultFound(item.countryCode, searchKey) || isSearchResultFound(item.countryName, searchKey),
      );
      setSearchedItems(searchResults);
    },
    [countries],
  );

  const renderCheckIcon = (countryCode: string) =>
    selectedCountry?.countryCode === countryCode ? <CheckMark stroke="var(--primary-color)" /> : <></>;

  return (
    <BottomOverlay title={title}>
      <SelectionView<SupportedCountries>
        keyExtractor={({ countryCode }) => countryCode}
        items={searchedItems ?? countries}
        searchable
        onSelectItem={onSelectCurrency}
        onSearch={countrySearchHandler}
        renderItem={({ countryCode, countryName }, index) => (
          <ListItem
            key={index}
            primaryText={countryName}
            secondaryText={countryCode}
            primaryIcon={
              <Icon
                iconSrc={`https://hatscripts.github.io/circle-flags/flags/${countryCode.toLowerCase()}.svg`}
                size="medium"
                avatarName={countryCode.toLowerCase()}
              />
            }
            secondaryIcon={renderCheckIcon(countryCode)}
          />
        )}
      />
    </BottomOverlay>
  );
};
export default CountryPicker;
