import { ethers } from 'ethers';

export const removeDuplicatesByProperty = <T>(array: T[], propertyName: string) =>
  array.reduce((unique: T[], o: T) => {
    if (!unique.some((obj: T) => obj[propertyName as keyof T] === o[propertyName as keyof T])) {
      unique.push(o);
    }
    return unique;
  }, []);

export const getObjectsInSuppliedArray = <T>(array: T[], propertyName: string, valueToFilter: string[]) =>
  array.filter((object) => valueToFilter.includes(object[propertyName as keyof T] as string));

export const removeObjectsInSuppliedArray = <T>(array: T[], propertyName: string, valueToFilter: string[]) =>
  array.filter((object) => !valueToFilter.includes(object[propertyName as keyof T] as string));

export const changeColorMagnitude = (hexColor: string, magnitude: number) => {
  // use + values for magnitude to lighter the color
  // use - values for magnitude to darken the color
  const newColor = hexColor.replace('#', '');
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  }
  return `#${newColor}`;
};

export const isSearchResultFound = (phrase: string, searchString: string) =>
  phrase
    .toLowerCase()
    .split(' ')
    .some((substring) => substring.toLowerCase().startsWith(searchString.toLowerCase())) ||
  phrase.toLowerCase().startsWith(searchString.toLowerCase());

export const roundNumber = (value: number, decimalPoints: number) => {
  if (typeof value !== 'number' || isNaN(value)) {
    // eslint-disable-next-line no-console
    console.warn('Invalid input for rounding. Expected a number.');
    return NaN;
  }

  try {
    return Number(value.toFixed(decimalPoints));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Unable to round provided number', error);
    return value;
  }
};

export const formatBalance = (balance: bigint | number, decimals: number) => ethers.formatUnits(balance, decimals);

export const convertToQueryString = (params: object) => {
  const queryString = Object.entries(params)
    .map(([key, value]) => (value ? `${key}=${value}&` : ''))
    .join('');
  return queryString.endsWith('&') ? queryString.slice(0, -1) : queryString;
};

export const isProd = () => process.env.REACT_APP_STAGE === 'prod'
