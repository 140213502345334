import i18 from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import enTranslations from "../locales/en/translation.json";
import frTranslations from "../locales/fr/translation.json";

import deTranslations from "../locales/de/translation.json";
import esTranslations from "../locales/es/translation.json";
import hiTranslations from "../locales/hi/translation.json";
import nlTranslations from "../locales/nl/translation.json";
import ruTranslations from "../locales/ru/translation.json";

export const supportedLanguages = {
  en: "English",
  fr: "French (Français)",
  de: "German (Deutsch)",
  nl: "Dutch (Nederlands)",
  //    si: 'Sinhala (සිංහල)',
  hi: "Hindi (हिन्दी)",
  ru: "Russian (Русский)",
  es: "Spanish (Español)",
};

const fallbackLng = "en";

const initI18n = (supportLocalization: boolean) => {
  const resources = {
    en: { translation: enTranslations },
    ...(supportLocalization
      ? {
          fr: { translation: frTranslations },
          nl: { translation: nlTranslations },
          // si: { translation: siTranslations },
          hi: { translation: hiTranslations },
          ru: { translation: ruTranslations },
          de: { translation: deTranslations },
          es: { translation: esTranslations },
        }
      : {}),
  };

  i18
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      detection: {
        order: [
          "querystring",
          "localStorage",
          "cookie",
          "navigator",
          "htmlTag",
        ],
        caches: ["localStorage", "cookie"],
      },
      fallbackLng: fallbackLng,
      debug: process.env.REACT_APP_STAGE !== "prod",
      interpolation: {
        escapeValue: true,
      },
      resources,
    });
};

export default initI18n;
