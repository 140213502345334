import * as endpoints from '../config/endpoints';
import { useParamContext } from '../providers/ParamContextProvider/ParamContextProvider';
import type { SupportedCountries } from '../types';

import { useFetch } from '../utils/reactQuery';

type SupportedCountryResponse = {
  message: SupportedCountries[];
};

type FetchParams = {
  country?: string;
};

export const useSupportedCountries = () => {
  const { params } = useParamContext();
  const { country } = params;
  const fetchParams: FetchParams = {};
  if (country) {
    fetchParams.country = country;
  }
  const { data, isLoading, isError, refetch } = useFetch<SupportedCountryResponse>(
    endpoints.supportedCountries,
    fetchParams,
    {
      enabled: false,
    },
  );

  return {
    supportedCountries: data?.message,
    refetch,
    isLoading,
    isError,
  };
};
