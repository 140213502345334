import { Button, Icon, List, ListItem, Menu, Switch } from "@onramper/oui";
import React, { FC, ReactNode, useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useThemes } from "../../../hooks/useThemes";
import { ReactComponent as ChevronRight } from "../../../icons/chevron-right.svg";
import { ReactComponent as ChevronUp } from "../../../icons/chevron-up.svg";
import { ReactComponent as DarkMode } from "../../../icons/menu/dark-mode.svg";
import { ReactComponent as HelpIcon } from "../../../icons/menu/help.svg";
import { ReactComponent as PrivacyPolicyIcon } from "../../../icons/menu/privacy-policy.svg";
import { ReactComponent as Logout } from "../../../icons/menu/logout.svg";
import { ReactComponent as TermsIcon } from "../../../icons/menu/terms.svg";
import { onrampMetadata } from "../../../metadata/onrampMetadata";
import { useParamContext, useThemeContext } from "../../../providers";
import { useNavigationContext } from "../../../providers/NavigationContextProvider";
import { Onramp } from "../../../types";
import CountrySelector from "../../common/CountrySelector";
import classes from "./MenuOverlayView.module.css";
import { useLanguage } from "../../../hooks/useLanguage";
import LanguageSelector from "../../common/LanguageSelector";
import { useAuthContext } from "../../../providers/AuthContextProvider";
import { useLogout } from "../../../hooks/useLogout";
import useAuthToken from "../../../hooks/useAuthToken";
import { SignIn } from "../SignInView";

export type MenuItem = {
  id: string;
  name: string;
  icon?: SvgrComponent | ReactNode;
  link?: string;
  subMenuItems?: MenuItem[];
  type?: string;
  disabled?: boolean;
};

const OverlayMenuView: FC = () => {
  const { formatMessage } = useLanguage();
  const { theme, setThemeName } = useThemeContext();
  const {
    params: { enableCountrySelector, supportLocalization },
  } = useParamContext();
  const { showDarkModeSwitch } = useThemes();
  const transitionRef = React.useRef(null);
  const [isActive, setIsActive] = useState(false);
  const { backScreen, nextScreen } = useNavigationContext();
  const { params } = useParamContext();
  const { enableAuth } = params;
  const { auth } = useAuthContext();
  const { isAuthenticated } = auth;
  const [activeSubMenuId, setActiveSubMenuId] = useState<string | null>(null);
  const { logout } = useLogout();
  const { email } = useAuthToken();

  useEffect(() => {
    setIsActive(true);
  }, []);

  const handleDismiss = () => {
    setIsActive((oldValue) => !oldValue);
    setTimeout(backScreen, transitionTimeout);
  };

  const listItemClickHandler = (item: MenuItem) => {
    if (item.subMenuItems) {
      setActiveSubMenuId(activeSubMenuId === item.id ? null : item.id);
    } else if (item.id === "logout") {
      logout();
    } else {
      item.link && window.open(item.link);
    }
  };

  const themeSwitchHandler = () => {
    theme.name !== "dark" ? setThemeName("dark") : setThemeName("light");
  };

  const renderRightMenuIcons = (item: MenuItem) => {
    if (item.id === "darkMode") {
      return (
        <Switch
          checked={theme.name === "dark"}
          onChange={() => themeSwitchHandler()}
        />
      );
    }

    if (item.id === "logout") {
      return <ChevronRight stroke="#141519" />;
    }

    return item.subMenuItems && activeSubMenuId === item.id ? (
      <ChevronUp stroke="#141519" />
    ) : (
      <ChevronRight stroke="#141519" />
    );
  };

  const renderSubMenuItems = (item: MenuItem) => (
    <div className={classes["overlay-menu-sub-menu"]}>
      <List>
        {item.subMenuItems?.map((subItem) => (
          <ListItem
            key={subItem.id}
            primaryText={subItem.name}
            primaryIcon={
              subItem.icon ? (
                <div
                  className={classes["overlay-menu-sub-menu-icon-contianer"]}
                >
                  <Icon iconSrc={subItem.icon} size="small" />
                </div>
              ) : null
            }
            iconSize="small"
            onClick={() => listItemClickHandler(subItem)}
            className={classes["oui-list-primary-text"]}
          />
        ))}
      </List>
    </div>
  );

  const getSupportedLinks = () => {
    const supportedObjects = Object.values(onrampMetadata)
      .map((onrampData: Onramp) => {
        if (onrampData.isAvailable && onrampData.supportLink) {
          return {
            id: onrampData.id,
            name: `${onrampData.name} Support`,
            icon: onrampData.icon,
            link: onrampData.supportLink,
          };
        }
        return null;
      })
      .filter(Boolean)
      .sort((a, b) => {
        if (a?.id && b?.id) {
          return a.id.localeCompare(b.id);
        }
        if (!a?.id && b?.id) {
          return -1;
        }
        if (a?.id && !b?.id) {
          return 1;
        }
        return 0;
      });

    return supportedObjects as MenuItem[];
  };

  const menuItems: MenuItem[] = [
    ...(supportLocalization
      ? [
          {
            id: "language",
            name: formatMessage("menuOverlayView.mainMenu.language"),
          },
        ]
      : []),
    {
      id: "privacyPolicy",
      name: formatMessage("menuOverlayView.mainMenu.privacyPolicy"),
      icon: PrivacyPolicyIcon,
      link: "https://onramper.com/privacy-policy",
    },
    {
      id: "termsOfUsage",
      name: formatMessage("menuOverlayView.mainMenu.termsOfUsage"),
      icon: TermsIcon,
      link: "https://onramper.com/terms-conditions/",
    },
    {
      id: "helpAndSUpport",
      name: formatMessage("menuOverlayView.mainMenu.helpAndSupport"),
      icon: HelpIcon,
      link: "https://onramper.com/FAQ",
      subMenuItems: getSupportedLinks(),
    },
    {
      id: "darkMode",
      name: formatMessage("menuOverlayView.mainMenu.darkMode"),
      icon: DarkMode,
    },
    {
      id: "logout",
      name: formatMessage("menuOverlayView.mainMenu.logout"),
      icon: Logout,
      disabled: !isAuthenticated,
    },
  ];

  return (
    <div className={`${classes["overlay-menu-root"]}`} onClick={handleDismiss}>
      <CSSTransition
        nodeRef={transitionRef}
        in={isActive}
        timeout={transitionTimeout}
        classNames={getTransitionClasses(classes)}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <div
          className={classes["overlay-menu"]}
          ref={transitionRef}
          onClick={(e) => e.stopPropagation()}
        >
          <Menu
            title={
              isAuthenticated && enableAuth ? (
                <div className={classes["user-info"]}>
                  <span className={classes["greeting"]}>
                    {formatMessage("menuOverlayView.mainMenu.welcome")}
                  </span>
                  <span className={classes["user-email"]}>{email}</span>
                </div>
              ) : (
                <></>
              )
            }
            onMenuCloseButtonClick={handleDismiss}
          >
            {!isAuthenticated && enableAuth && (
              <div className={classes["login-prompt"]}>
                <span className={classes["login-prompt-heading"]}>
                  {formatMessage("menuOverlayView.mainMenu.title")}
                </span>
                <span className={classes["login-prompt-subheading"]}>
                  {formatMessage("menuOverlayView.mainMenu.subTitle")}
                </span>
                <Button
                  label={formatMessage("menuOverlayView.mainMenu.loginButton")}
                  variant="primary"
                  size="small-circular"
                  onClick={() => nextScreen(<SignIn signInFrom="menu" />)}
                />
              </div>
            )}
            {enableCountrySelector && (
              <div className={classes["sub-menu-container"]}>
                <List>
                  <p className={classes["sub-menu-title"]}>
                    {formatMessage("menuOverlayView.mainMenu.location")}
                  </p>
                  <CountrySelector />
                </List>
                <div className={classes["menu-separator"]}></div>
              </div>
            )}

            <List>
              {menuItems.map((item, idx) => {
                if (item.disabled) return null;
                if (item.id === "language" && supportLocalization) {
                  return <LanguageSelector key={item.id} />;
                }
                if (item.id === "darkMode" && !showDarkModeSwitch()) {
                  return null;
                }
                return (
                  <div key={idx}>
                    <ListItem
                      key={item.id}
                      primaryText={item.name}
                      primaryIcon={item?.icon && <item.icon />}
                      onClick={() => listItemClickHandler(item)}
                      secondaryIcon={renderRightMenuIcons(item)}
                    />
                    {item.subMenuItems &&
                      activeSubMenuId === item.id &&
                      renderSubMenuItems(item)}
                  </div>
                );
              })}
            </List>
          </Menu>
        </div>
      </CSSTransition>
    </div>
  );
};

export default OverlayMenuView;

export const transitionTimeout = 300;
export const getTransitionClasses = (classes: {
  [className: string]: string;
}) => ({
  enter: classes["collapse-enter"],
  enterActive: classes["collapse-enter-active"],
  exit: classes["collapse-exit"],
  exitActive: classes["collapse-exit-active"],
});
