import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useIndexedDbContext } from "../providers";

const useAuthToken = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState<boolean>(true);
  const { authToken } = useIndexedDbContext();

  useEffect(() => {
    setLoading(true);
    if (authToken) {
      try {
        const decoded: any = jwtDecode(authToken);
        const emailFromToken = decoded[
          "https://streambird.io/jwt/session"
        ].factors.find(
          (factor: { type: string; method: { method_type: string } }) =>
            factor.type === "otp" && factor.method.method_type === "email"
        ).method.email;

        setEmail(emailFromToken);
      } catch (error) {
        console.error("Failed to decode JWT or extract email:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [authToken]);

  return { email, loading };
};

export default useAuthToken;
