import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as CountryIcon } from '../../../icons/menu/country.svg';

import { Icon, ListItem } from '@onramper/oui';
import { useCurrencies } from '../../../hooks/useCurrencies';
import { useDefaults } from '../../../hooks/useDefaults';
import { useSupportedCountries } from '../../../hooks/useSupportedCountries';
import { ReactComponent as ChevronDown } from '../../../icons/chevron-down.svg';
import { useNavigationContext, useParamContext, useTransactionContext } from '../../../providers';
import { SupportedCountries } from '../../../types';

import CountryPicker from './CountryPicker/CountryPicker';
import styles from './CountrySelector.module.css';
import { useLanguage } from '../../../hooks/useLanguage';

const CountrySelector = () => {
  const { formatMessage } = useLanguage();

  /* --------------- context providers ------------------ */
  const { nextScreen, backScreen } = useNavigationContext();
  const { params } = useParamContext();
  const { transaction, setSelectedCountry, setSelectedFiat, setSelectedCrypto } = useTransactionContext();

  const { country: paramCountry } = params;
  const { selectedCountry, transactionType } = transaction;

  /* ------------------ data fetch ---------------------- */
  const { defaults, countryDefault, refetch: fetchDefaults } = useDefaults(undefined, transactionType ?? 'buy');
  const { supportedCountries } = useSupportedCountries();
  const { getCryptoCurrencyById, getFiatCurrencyById, getFirstCrypto, getFirstFiat } = useCurrencies();

  /* ------------------ states -------------------------- */
  const [selectedCountryIcon, setSelectedCountryIcon] = useState<SvgrComponent>(CountryIcon);
  const findCountry = useCallback(
    (countryCode: string) =>
      supportedCountries?.find((item) => item.countryCode.toUpperCase() === countryCode?.toUpperCase()),
    [supportedCountries],
  );

  useEffect(() => {
    fetchDefaults();
  }, [fetchDefaults, transactionType]);

  useEffect(() => {
    if (supportedCountries) {
      const countryParameter = findCountry(paramCountry ?? '');

      if (countryParameter) {
        setSelectedCountry(countryParameter);
      }
    }
  }, [findCountry, paramCountry, setSelectedCountry, supportedCountries]);

  useEffect(() => {
    if (!selectedCountry && countryDefault) {
      const foundCountry = findCountry(countryDefault.country);
      if (foundCountry) {
        setSelectedCountry(foundCountry);
      }
    }
  }, [countryDefault, findCountry, selectedCountry, setSelectedCountry]);

  useEffect(() => {
    if (selectedCountry && defaults) {
      const selectedDefaults = defaults[selectedCountry?.countryCode.toLowerCase()];
      if (selectedDefaults) {
        const { source, target } = selectedDefaults;
        if (transactionType === 'buy') {
          setSelectedFiat(getFiatCurrencyById(source) ?? getFirstFiat());
          setSelectedCrypto(getCryptoCurrencyById(target) ?? getFirstCrypto());
        } else {
          setSelectedFiat(getFiatCurrencyById(target) ?? getFirstFiat());
          setSelectedCrypto(getCryptoCurrencyById(source) ?? getFirstCrypto());
        }
      } else {
        setSelectedFiat(getFirstFiat());
        setSelectedCrypto(getFirstCrypto());
      }
    }
  }, [
    defaults,
    getCryptoCurrencyById,
    getFiatCurrencyById,
    getFirstCrypto,
    getFirstFiat,
    selectedCountry,
    setSelectedCountry,
    setSelectedCrypto,
    setSelectedFiat,
    transactionType,
  ]);

  useEffect(() => {
    if (selectedCountry) {
      setSelectedCountryIcon(
        <Icon
          iconSrc={`https://hatscripts.github.io/circle-flags/flags/${selectedCountry.countryCode.toLowerCase()}.svg`}
          size="medium"
          avatarName={selectedCountry.countryCode.toLowerCase()}
        />,
      );
    }
  }, [selectedCountry]);

  const handleCountrySelect = useCallback(
    (item: SupportedCountries): void => {
      // setCountry(item);
      setSelectedCountry(item);
      backScreen();
      backScreen();
    },
    [backScreen, setSelectedCountry],
  );

  const openCountrySelector = () => {
    if (supportedCountries) {
      nextScreen(
        <CountryPicker
          key="CountryPicker"
          title={formatMessage('countrySelector.countryPicker.title')}
          countries={supportedCountries}
          onSelectCurrency={handleCountrySelect}
        />,
      );
    }
  };

  return (
    <ListItem
      key="country-selector"
      primaryText={selectedCountry ? selectedCountry.countryName : formatMessage('countrySelector.countryPicker.title')}
      primaryIcon={selectedCountryIcon}
      onClick={openCountrySelector}
      secondaryIcon={<ChevronDown className={styles['dropdown-icon']} width="16px" height="16px" stroke="#9EA2B3" />}
      disabled={paramCountry !== null}
    />
  );
};

export default CountrySelector;
