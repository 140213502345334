import React, { useEffect, useState } from 'react';
import commonStyles from '../../../common-styles.module.css';
import styles from './BottomOverlay.module.css';
import { CSSTransition } from 'react-transition-group';
import { useNavigationContext } from '../../../providers/NavigationContextProvider';
import { BottomOverlayProps } from './BottomOverlayProps';
import { defaultAnimTimeout, getTransitionClasses } from './constants';
import OverlayHeader from './OverlayHeader/OverlayHeader';

const BottomOverlay: React.FC<BottomOverlayProps> = (props) => {
  const { maxHeight = '95%', fixedHeight = false, color, title, children, onClose } = props;
  const transitionRef = React.useRef(null);
  const { backScreen } = useNavigationContext();
  const [isActive, setIsActive] = useState(false);

  const classPrefix = fixedHeight ? '-fixed' : '';

  useEffect(() => {
    setIsActive(true);
  }, []);

  const handleDismiss = () => {
    setIsActive((oldValue) => !oldValue);
    setTimeout(backScreen, defaultAnimTimeout);
    if (onClose) {
      onClose();
    }
  };

  const style = {
    '--bottom-drawer-max-height': maxHeight,
    backgroundColor: color,
  } as React.CSSProperties;

  return (
    <>
      <div className={`${commonStyles.view} ${styles['bottom-overlay-view']}`} onClick={handleDismiss}>
        <CSSTransition
          nodeRef={transitionRef}
          in={isActive}
          timeout={defaultAnimTimeout}
          classNames={getTransitionClasses(styles, classPrefix)}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <div
            ref={transitionRef}
            style={style}
            onClick={(e) => e.stopPropagation()}
            className={`${styles['bottom-overlay-drawer']} ${styles[`bottom-overlay-drawer${classPrefix}`]}`}
          >
            {title && <OverlayHeader text={title} onClose={handleDismiss} closeButton />}
            {children}
          </div>
        </CSSTransition>
      </div>
    </>
  );
};

export default BottomOverlay;
