import React, { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
// import styles from './FadeTransition.module.css';

type FadeTransitionProps = {
  children: ReactNode;
  in?: boolean;
  timeout: number;
  classNames?: any;
  unmountOnExit?: boolean;
};

const FadeTransition: React.FC<FadeTransitionProps> = ({ children, ...props }) => (
  <CSSTransition {...props}>{children}</CSSTransition>
);

export default FadeTransition;
