import React, { FC, useEffect, useState } from "react";
import { ReactComponent as OnramperLogoFull } from "../../../icons/onramper-logo-full.svg";
import { ReactComponent as OnramperLogoRounded } from "../../../icons/onramper-logo.svg";
import { ReactComponent as OnramperLogo } from '../../../icons/onramper-full-logo.svg';
import { ReactComponent as SmallOnramperLogo } from '../../../icons/small-onramper-logo.svg';

import { useLanguage } from "../../../hooks/useLanguage";
import commonStyles from "../../../common-styles.module.css";
import styles from './Branding.module.css';
import { useParamContext, useThemeContext } from "../../../providers";

type BrandingProps = {
    type: "full" | "rounded";
    size?: 'sm' | 'md' | 'lg';
};

const Branding: FC<BrandingProps> = ({ type, size }) => {
    const { formatMessage } = useLanguage();
    const { params: { apiKey } } = useParamContext();
    const { theme: { name: themeName } } = useThemeContext();

    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [imageLoaded, setImageLoaded] = useState<boolean | null>(null);
    const [roundedImageAvailable, setRoundedImageAvailable] = useState<boolean | null>(null);

    const imageUrl = `https://cdn.onramper.com/premium/custom_logos/${apiKey}/${type}-${themeName}.svg`;
    const roundedImageUrl = `https://cdn.onramper.com/premium/custom_logos/${apiKey}/rounded-${themeName}.svg`;

    useEffect(() => {
        // Check if the rounded image is available
        const checkRoundedImage = new Image();
        checkRoundedImage.src = roundedImageUrl;

        checkRoundedImage.onload = () => {
            setRoundedImageAvailable(true);
        };

        checkRoundedImage.onerror = () => {
            setRoundedImageAvailable(false);
        };

        const img = new Image();
        img.src = imageUrl;

        img.onload = () => {
            setImageSrc(imageUrl);
            setImageLoaded(true);
        };

        img.onerror = () => {
            setImageLoaded(false);
        };

        return () => {
            setImageSrc(null);
            setImageLoaded(null);
        };
    }, [imageUrl, roundedImageUrl]);

    const renderDefaultBranding = () => {
        // If rounded image is available but full image is not, return null
        if (type === 'full' && roundedImageAvailable === true && imageLoaded === false) {
            return null;
        }

        // If rounded image is not available, proceed with normal fallback logic
        if (type === 'rounded') {
            if (size === 'lg') {
                return <OnramperLogo />;
            }

            if (size === 'sm') {
                return <SmallOnramperLogo className={styles['sm-onramper-logo']} />;
            }

            return <OnramperLogoRounded />;
        }

        return (
            <div className={styles['onramper-logo-full']}>
                <p className={commonStyles["container-footer-text"]}>
                    {formatMessage("containerView.footer.poweredBy")}
                </p>
                <OnramperLogoFull className={commonStyles["container-footer-logo"]} />
            </div>
        );
    };

    if (imageLoaded === true && imageSrc) {
        return (
            <img
                src={imageSrc}
                alt="Logo"
                className={`
                    ${styles[`brand-logo-${type}`]}
                    ${size === 'lg' ? styles['logo-lg'] : ''}
                    ${size === 'sm' ? styles['logo-sm'] : ''}
                `}
            />
        );
    } else if (imageLoaded === false) {
        return <a href="https://www.onramper.com/" target="_blank" rel="noreferrer">{renderDefaultBranding()}</a>;
    }

    if (type === 'full') {
        return null;
    }

    return <div
        style={{ borderRadius: '50%', backgroundColor: 'var(--card-color)' }}
        className={`
        ${styles[`brand-logo-${type}`]}
        ${size === 'lg' ? styles['logo-lg'] : ''}
        ${size === 'sm' ? styles['logo-sm'] : ''}
    `}
    />;
};

export default Branding;