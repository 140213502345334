let windowObjectReference: Window | null = null;

export const openWindow = (url: string, type: 'popup' | 'tab') => {
  const width = 440;
  const height = 595;
  const left = window.screen.width / 2 - (width / 2 + 10);
  const top = window.screen.height / 2 - (height / 2 + 50);

  if (windowObjectReference && !windowObjectReference.closed) {
    windowObjectReference.location.href = url;
    windowObjectReference.focus();
  } else {
    windowObjectReference = window.open(
      url,
      '_blank',
      type === 'popup' ? `height=${height},width=${width},scrollbars=yes,left=${left},top=${top}` : '',
    );
    if (windowObjectReference) {
      const interval = 250;
      const times2Count = (1000 * 60) / interval;
      let count = 0;
      const checkIfClosed = setInterval(() => {
        if (windowObjectReference && (windowObjectReference.closed || count > times2Count)) {
          clearInterval(checkIfClosed);
        }
        count++;
      }, interval);
    }
  }
};
