export type ThemeName = 'light' | 'dark' | 'bluey' | 'sushi';

export type ThemeContextProps = {
  theme: Theme;
  setThemeName: (themeName: ThemeName) => void;
};

export type Theme = {
  name: ThemeName;
};

export enum ThemeActionType {
  SetThemeName = 'SET_THEME_NAME',
}

export type ThemeActions = {
  type: ThemeActionType.SetThemeName;
  payload: ThemeName;
};
