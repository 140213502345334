import { SwapTransaction } from './types';

export const initialState: SwapTransaction = {
  selectedSource: null,
  selectedTarget: null,
  sourceAmount: 0,
  targetAmount: 0,
  sourceWalletAddress: null,
  sourceWalletAddressTag: null,
  targetWalletAddress: null,
  targetWalletAddressTag: null,
  transactionHash: null,
  txId: null,
  quotes: [],
  selectedProvider: null,
  error: null,
};
