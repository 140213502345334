import { NotificationId } from '../../types';

export type NotificationContextProps = {
  notifications: Notifications;
  addNotification: (payload: AddNotificationPayload) => void;
  removeNotification: (id: NotificationId) => void;
  removeAllNotifications: () => void;
};

export type NotificationType = 'error' | 'info' | 'success';

export enum NotificationActionType {
  AddNotification = 'ADD_NOTIFICATION',
  RemoveNotification = 'REMOVE_NOTIFICATION',
  RemoveAllNotifications = 'REMOVE_ALL_NOTIFICATIONS',
}

export type Notifications = Notification[];

export interface AddNotificationPayload {
  id?: NotificationId;
  title: string;
  message?: string;
  type: NotificationType;
  shouldExpire: boolean;
}

export type Notification = {
  submittedAt: number;
  id?: NotificationId;
  title: string;
  message?: string;
  type: NotificationType;
  shouldExpire: boolean;
  externalId?: string;
};

export type NotificationActions =
  | {
      type: NotificationActionType.AddNotification;
      notification: Notification;
    }
  | {
      type: NotificationActionType.RemoveNotification;
      id: string;
    }
  | {
      type: NotificationActionType.RemoveAllNotifications;
    };
