import { Transaction } from '../../types';

export const initialState: Transaction = {
  selectedFiat: null,
  selectedCrypto: null,
  fiatAmount: 0,
  cryptoAmount: 0,
  quotes: [],
  selectedOnramp: null,
  selectedPaymentMethod: null,
  selectedLinkIntegration: null,
  selectedCountry: null,
  isRecurringPayment: false,
  transactionType: null,
  recurringPaymentMetadata: null,
  error: null,
  isOtcTxn: false,
};
