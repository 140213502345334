import React, { useCallback } from 'react';
import { ReactComponent as LanguageIcon } from '../../../icons/menu/language.svg';

import {  ListItem } from '@onramper/oui';
import { ReactComponent as ChevronDown } from '../../../icons/chevron-down.svg';
import { useNavigationContext } from '../../../providers';
import { SupportedLocales } from '../../../types';

import CountryPicker from './LanguagePicker/LanguagePicker';
import styles from './LanguageSelector.module.css';
import { useLanguage } from '../../../hooks/useLanguage';
import { supportedLanguages } from '../../../i18n/config';

const LanguageSelector = () => {

  const { formatMessage, changeLanguage } = useLanguage();

  /* --------------- context providers ------------------ */
  const { nextScreen, backScreen } = useNavigationContext();


  /* ------------------ states -------------------------- */


  const getSupportedLanguages = () => Object.entries(supportedLanguages).map(([localeCode, localeName]) => ({
        localeCode,
        localeName,
      }));
  

  const handleLanguageSelect = useCallback(
    (item: SupportedLocales): void => {      
      changeLanguage(item.localeCode)
      backScreen();
      backScreen();
    },
    [backScreen, changeLanguage],
  );

  const openLanguagePicker = () => {
    if (getSupportedLanguages()) {
      nextScreen(
        <CountryPicker
          key="CountryPicker"
          title={formatMessage('languagePicker.title')}
          locales={getSupportedLanguages()}
          onSelectLanguage={handleLanguageSelect}
        />,
      );
    }
  };

  return (
    <ListItem
      key="language-selector"
      primaryText={formatMessage('menuOverlayView.mainMenu.language')}
      primaryIcon={<LanguageIcon/>}
      onClick={openLanguagePicker}
      secondaryIcon={<ChevronDown className={styles['dropdown-icon']} width="16px" height="16px" stroke="#9EA2B3" />}      
    />
  );
};

export default LanguageSelector;
