import { Auth } from "../../types";

export const initialState: Auth = {
  isAuthenticated: false,
  authError: "None",
  onramperId: null,
  authToken: null,
  email: null,
  otp: null,
};
