import React, { FC } from 'react';
import { Icon } from '@onramper/oui';
import styles from './ExternalView.module.css';

type PendingViewProps = {
  iconSource: string;
};

const PendingView: FC<PendingViewProps> = ({ iconSource }: PendingViewProps) => (
  <div className={styles['pending-image-container']}>
    <div className={styles['pending-image-onramp']}>
      <Icon size="medium" format="base64" iconSrc={iconSource} />
    </div>
    <svg width="206" height="119" viewBox="0 0 206 119" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2492_9601)">
        <rect
          x="0.999812"
          y="12.7501"
          width="158.885"
          height="11.4423"
          stroke="var(--primary-text-color)"
          strokeWidth="1.96154"
        />
        <circle cx="8.95515" cy="18.918" r="1.78718" fill="var(--primary-text-color)" />
        <circle cx="15.21" cy="18.918" r="1.78718" fill="var(--primary-text-color)" />
        <circle cx="21.4649" cy="18.918" r="1.78718" fill="var(--primary-text-color)" />
      </g>
      <rect
        x="0.999812"
        y="12.7501"
        width="158.885"
        height="105.269"
        rx="2.94231"
        stroke="var(--primary-text-color)"
        strokeWidth="1.96154"
      />
      <g clipPath="url(#clip1_2492_9601)">
        <rect x="110.519" width="95.4615" height="88.9231" rx="3.92308" fill="var(--background-color)"></rect>
        <rect
          x="111.5"
          y="0.980769"
          width="93.5"
          height="11.1154"
          stroke="var(--primary-text-color)"
          strokeWidth="1.96154"
        />
        <circle cx="118.801" cy="6.9743" r="1.74359" fill="var(--primary-text-color)" />
        <circle cx="124.903" cy="6.9743" r="1.74359" fill="var(--primary-text-color)" />
        <circle cx="131.006" cy="6.9743" r="1.74359" fill="var(--primary-text-color)" />

        {/* <circle cx="158.019" cy="44" r="16" fill="url(#paint0_linear_2492_9601)" /> */}
      </g>
      <rect
        x="111.5"
        y="0.980769"
        width="93.5"
        height="86.9615"
        rx="2.94231"
        stroke="var(--primary-text-color)"
        strokeWidth="1.96154"
      ></rect>
      <defs>
        <clipPath id="clip0_2492_9601">
          <rect x="0.019043" y="11.7693" width="160.846" height="107.231" rx="3.92308" fill="var(--background-color)" />
        </clipPath>
        <clipPath id="clip1_2492_9601">
          <rect x="110.519" width="95.4615" height="88.9231" rx="3.92308" fill="var(--background-color)" />
        </clipPath>
      </defs>
    </svg>
  </div>
);

export default PendingView;
