/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "react-query";
import { useAuthContext } from "../providers/AuthContextProvider";
import { useParamContext } from "../providers/ParamContextProvider/ParamContextProvider";
import { fetchApi } from "./fetchApi";
import { useIndexedDbContext } from "../providers";
import { useLogout } from "../hooks/useLogout";

type QueryKeyT = [string, object | undefined];

export const useFetch = <T>(
  url: string | null,
  params?: object,
  config?: UseQueryOptions<T, Error, T, QueryKeyT>,
  customHeaders?: HeadersInit
) => {
  const {
    params: { apiKey },
  } = useParamContext();
  const { logout } = useLogout();
  const { setAuthError } = useAuthContext();
  const { authToken, onramperId } = useIndexedDbContext();
  const {
    params: { enableAuth },
  } = useParamContext();

  let headers: HeadersInit;

  if (customHeaders) {
    headers = customHeaders;
  } else {
    headers = {
      Authorization: apiKey ?? "",
    };

    if (enableAuth) {
      if (authToken) {
        headers["x-onramper-auth" as keyof typeof headers] = authToken;
      }

      if (onramperId) {
        headers["x-onramper-id" as keyof typeof headers] = onramperId;
      }
    }
  }

  const context = useQuery<T, Error, T, QueryKeyT>(
    [url!, params],
    ({ queryKey }) => {
      const [url, params] = queryKey;
      return fetcher(url, params, headers);
    },
    {
      enabled: !!url,
      ...config,
      retry: false,
      onError: async (error: any) => {
        // If a 401 error is returned while freshing the token, the user is logged out
        if (error.errorId === 401) {
          setAuthError("FailedToRefreshToken");
          logout();
        }
      },
    }
  );
  return context;
};

const useGenericMutation = <T, S>(
  func: (data: T | S | void) => Promise<S>,
  url: string,
  params?: object,
  updater?: ((oldData: T, newData: S) => T) | undefined
) => {
  const queryClient = useQueryClient();

  return useMutation<T | S>(func, {
    // When mutate is called:
    onMutate: async (data) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([url!, params]);

      // Snapshot the previous value
      const previousData = queryClient.getQueryData([url!, params]);

      // Optimistically update to the new value
      queryClient.setQueryData<T>([url!, params], (oldData) =>
        updater ? updater(oldData!, data as S) : (data as T)
      );

      return previousData;
    },
    onError: (err, _, context) => {
      queryClient.setQueryData([url!, params], context);
    },
    onSettled: () => {
      queryClient.invalidateQueries([url!, params]);
    },
  });
};

export const fetcher = async <T>(
  url: string,
  params?: object,
  headers?: any
): Promise<T> => {
  return fetchApi.get<T>(url, params, headers);
};

export const usePost = <T, S>(
  url: string,
  customHeaders?: HeadersInit,
  data?: object,
  updater?: (oldData: T, newData: S) => T
) => {
  const {
    params: { apiKey },
  } = useParamContext();
  const { authToken, onramperId } = useIndexedDbContext();
  const {
    params: { enableAuth },
  } = useParamContext();

  const headers: HeadersInit = {
    ...(customHeaders ?? {}),
    Authorization: apiKey ?? "",
    accept: "application/json",
    "Content-Type": "application/json",
  };

  if (enableAuth) {
    if (authToken) {
      headers["x-onramper-auth" as keyof typeof headers] = authToken;
    }

    if (onramperId) {
      headers["x-onramper-id" as keyof typeof headers] = onramperId;
    }
  }

  return useGenericMutation<T, S>(
    () => fetchApi.post<S>(url, headers, data),
    url,
    data,
    updater
  );
};

export const usePatch = <T, S>(
  url: string,
  customHeaders?: HeadersInit,
  data?: object,
  updater?: (oldData: T, newData: S) => T
) => {
  const {
    params: { apiKey },
  } = useParamContext();
  const { authToken, onramperId } = useIndexedDbContext();
  const {
    params: { enableAuth },
  } = useParamContext();

  const headers: HeadersInit = {
    ...(customHeaders ?? {}),
    Authorization: apiKey ?? "",
    accept: "application/json",
    "Content-Type": "application/json",
  };

  if (enableAuth) {
    if (authToken) {
      headers["x-onramper-auth" as keyof typeof headers] = authToken;
    }

    if (onramperId) {
      headers["x-onramper-id" as keyof typeof headers] = onramperId;
    }
  }

  return useGenericMutation<T, S>(
    () => fetchApi.patch<S>(url, headers, data),
    url,
    data,
    updater
  );
};
