import { verifyOtp } from "../config/endpoints";
import { useIndexedDbContext } from "../providers";
import { useAuthContext } from "../providers/AuthContextProvider";
import { VerifyOtpResponse } from "../types";
import { usePost } from "../utils/reactQuery";

export const useVerifyOtp = () => {
  const url = verifyOtp;
  const { auth } = useAuthContext();
  const { otp } = auth;
  const { onramperId } = useIndexedDbContext();

  const { data, isLoading, error, isError, mutate } = usePost<
    VerifyOtpResponse,
    VerifyOtpResponse
  >(
    url,
    {},
    {
      onramperId,
      otp,
    }
  );

  return {
    data,
    isLoading,
    error,
    isError,
    mutate,
  };
};
