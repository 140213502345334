import { OnramperWidgetProps } from '../../types';
import { ParamsContextActions, ParamContextActionType } from './types';

export const paramStateReducer = (params: OnramperWidgetProps, action: ParamsContextActions) => {
  switch (action.type) {
    case ParamContextActionType.SET_PARAMS:
      return { ...params, ...action.value };
    default:
      throw new Error('Unhandled action type');
  }
};
