import React from 'react';
import styles from './LoadingArrow.module.css';
import { ReactComponent as LoadArrow } from '../../../icons/load-arrow.svg';

const LoadingArrow = () => (
  <>
    <div className={styles.load}>
      <div className={styles['scroll-prompt-arrow-container']}>
        <div className={styles['scroll-prompt-arrow']}>
          <LoadArrow />
        </div>
        <div className={styles['scroll-prompt-arrow']}>
          <LoadArrow />
        </div>
        <div className={styles['scroll-prompt-arrow']}>
          <LoadArrow />
        </div>
      </div>
    </div>
  </>
);

export default LoadingArrow;
