import {
  NavigationActions,
  NavigationActionType,
  NavigationStateType,
} from "./types";

export const mainReducer = (
  state: NavigationStateType,
  action: NavigationActions
) => ({
  screens: navigationReducer(state, action),
  currentSes:
    action.type === NavigationActionType.Only
      ? state.currentSes + 1
      : state.currentSes,
});

export const navigationReducer = (
  state: NavigationStateType,
  action: NavigationActions
) => {
  const { screens } = state;
  switch (action.type) {
    case NavigationActionType.Only:
      return [action.screen];
    case NavigationActionType.First:
      return [screens[0]];
    case NavigationActionType.Push:
      return [...screens, action.screen];
    case NavigationActionType.Pop:
      if (screens.length <= 1) {
        return screens;
      }
      return [...screens.slice(0, -1)];
    case NavigationActionType.BackScreens:
      const { numberOfScreens } = action;
      if (screens.length <= numberOfScreens) {
        return screens;
      }
      return [...screens.slice(0, -numberOfScreens)];
    case NavigationActionType.Replace:
      return [...screens.slice(0, -1), action.screen];
    default:
      throw new Error("Unhandled action type");
  }
};
