import type {
  CryptoCurrency,
  FiatCurrency,
  Onramp,
  PaymentMethod,
  Quotes,
  RecurringPaymentMetadata,
  SupportedCountries,
  Transaction,
  TransactionError,
  TransactionType,
} from '../../types';

export type TransactionContextProps = {
  transaction: Transaction;
  setTransactionType: (TransactionType: TransactionType) => void;
  setSelectedFiat: (currency: FiatCurrency | null) => void;
  setSelectedCrypto: (currency: CryptoCurrency | null) => void;
  setSelectedOnramp: (onramp: Onramp | null) => void;
  setSelectedPaymentMethod: (paymentMethod: PaymentMethod | null) => void;
  setSelectedLinkIntegration: (linkIntegration: string | null) => void;
  setQuotes: (quotes: Quotes | null) => void;
  setFiatAmount: (amount: number) => void;
  setCryptoAmount: (amount: number) => void;
  setSelectedCountry: (country: SupportedCountries) => void;
  setRecurringPayment: (isRecurringPayment: boolean) => void;
  setRecurringPaymentMetadata: (recurringPaymentMetadata: RecurringPaymentMetadata) => void;
  resetTransaction: () => void;
  setTransactionError: (error: TransactionError | null) => void;
  setOtcTxn(isOtcTxn: boolean): void;
};

export enum TransactionActionType {
  SetSelectedFiat = 'SET_SELECTED_FIAT',
  SetSelectedCrypto = 'SET_SELECTED_CRYPTO',
  SetFiatAmount = 'SET_FIAT_AMOUNT',
  SetCryptoAmount = 'SET_CRYPTO_AMOUNT',
  SetSelectedOnramp = 'SET_SELECTED_ONRAMP',
  SetSelectedPaymentMethod = 'SET_SELECTED_PAYMENT_METHOD',
  SetSelectedLinkIntegration = 'SET_SELECTED_LINK_INTEGRATION',
  SetQuotes = 'SET_QUOTES',
  SetSelectedCountry = 'SET_COUNTRY',
  SetRecurringPayment = 'SET_RECURRING_PAYMENT',
  SetRecurringPaymentMetadata = 'SET_RECURRING_PAYMENT_METADATA',
  SetTransactionType = 'SET_TRANSACTION_TYPE',
  ResetTransaction = 'RESET_TRANSACTION',
  SetTransactionError = 'TRANSACTION_ERROR',
  SetOtcTxn = 'SET_OTC_TXN',
}

export type TransactionActions =
  | {
      type: TransactionActionType.SetSelectedCrypto;
      payload: CryptoCurrency | null;
    }
  | {
      type: TransactionActionType.SetSelectedFiat;
      payload: FiatCurrency | null;
    }
  | {
      type: TransactionActionType.SetFiatAmount;
      payload: number;
    }
  | {
      type: TransactionActionType.SetCryptoAmount;
      payload: number;
    }
  | {
      type: TransactionActionType.SetSelectedOnramp;
      payload: Onramp | null;
    }
  | {
      type: TransactionActionType.SetSelectedPaymentMethod;
      payload: PaymentMethod | null;
    }
  | {
      type: TransactionActionType.SetSelectedLinkIntegration;
      payload: string | null;
    }
  | {
      type: TransactionActionType.SetQuotes;
      payload: Quotes | null;
    }
  | {
      type: TransactionActionType.SetSelectedCountry;
      payload: SupportedCountries | null;
    }
  | {
      type: TransactionActionType.SetRecurringPayment;
      payload: boolean;
    }
  | {
      type: TransactionActionType.SetRecurringPaymentMetadata;
      payload: RecurringPaymentMetadata | null;
    }
  | { type: TransactionActionType.SetTransactionType; payload: TransactionType | null }
  | { type: TransactionActionType.ResetTransaction; payload: null }
  | { type: TransactionActionType.SetTransactionError; payload: TransactionError | null }
  | { type: TransactionActionType.SetOtcTxn; payload: boolean };
