export enum ERROR_CODES {
  COUNTRY_BLOCKED = 451,
  SIGNATURE_VALIDATION_ERROR = 4011,
}

class CoreError extends Error {
  public errorId: number;
  public message: string;

  constructor(id: number, message: string) {
    super(message);
    this.errorId = id;
    this.message = message;
  }
}

export class APIError extends Error {
  public errorId: number;
  public message: string;

  constructor(id: number, message: string) {
    super(message);
    this.errorId = id;
    this.message = message;
  }
}
