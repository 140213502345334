import { ReactNode } from 'react';
import { OnramperWidgetProps } from '../../types';

export type ParamInitialContextProps = {
  setParams: (data: OnramperWidgetProps) => void;
  params: OnramperWidgetProps;
};

export enum ParamContextActionType {
  SET_PARAMS = 'SET_PARAMS',
}

export type ParamsContextActions = {
  type: ParamContextActionType.SET_PARAMS;
  value?: OnramperWidgetProps;
};

export type ParamContextProps = {
  children?: ReactNode;
  widgetParams: OnramperWidgetProps;
};
