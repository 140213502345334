import { useMemo } from "react";
import { useCookies } from "react-cookie";
import * as endpoints from "../config/endpoints";
import { useParamContext, useTransactionContext } from "../providers";
import themeStyles from "../themes/themes.module.css";
import { CheckoutIntentResponse, Theme } from "../types";
import { usePost } from "../utils/reactQuery";
import {
  getNetworkWalletAddress,
  getWalletAddress,
  getWalletAddressTag,
} from "../utils/walletAddressUtils";
import { useThemes } from "./useThemes";
import { useLanguage } from "./useLanguage";
import { convertToQueryString } from "../utils/commonUtils";

export const useCheckoutIntent = () => {
  const [cookies] = useCookies(["uuid"]);
  const { transaction } = useTransactionContext();
  const { getThemeClass, getCssStyling } = useThemes();
  const { params } = useParamContext();
  const { country } = params;
  const {
    apiKey,
    successRedirectUrl,
    failureRedirectUrl,
    partnerContext,
    skipTransactionScreen,
    buy,
    offrampCashoutRedirectUrl,
    txnPaymentMethod,
  } = params;

  const { getLanguage } = useLanguage();
  const {
    transactionType,
    fiatAmount,
    cryptoAmount,
    selectedOnramp,
    selectedFiat,
    selectedCrypto,
    selectedPaymentMethod,
    isRecurringPayment,
    selectedCountry,
    recurringPaymentMetadata,
  } = transaction;

  const { wallets, walletAddressTags, networkWallets, signature } = params.buy;

  const { walletAddress, walletAddressTag } = useMemo(() => {
    let memoWalletAddress: string | null = null;
    let memoWalletAddressTag: string | null = null;
    if (wallets) {
      memoWalletAddress = getWalletAddress(
        wallets,
        selectedCrypto?.id.toLowerCase() ?? ""
      );
      if (walletAddressTags) {
        memoWalletAddressTag = getWalletAddressTag(
          walletAddressTags,
          selectedCrypto?.id.toLowerCase() ?? ""
        );
      }
    } else if (networkWallets) {
      memoWalletAddress = getNetworkWalletAddress(
        networkWallets,
        selectedCrypto?.network.toLowerCase() ?? ""
      );
    }

    return {
      walletAddress: memoWalletAddress,
      walletAddressTag: memoWalletAddressTag,
    };
  }, [wallets, networkWallets, walletAddressTags, selectedCrypto]);

  const signContent = useMemo(() => {
    // These parameters are rearanged alphabetically before regenerating the signature in backend
    if (signature) {
      const formattedQueryString = convertToQueryString({
        networkWallets,
        walletAddressTags,
        wallets,
      });
      return formattedQueryString;
    }
  }, [networkWallets, signature, walletAddressTags, wallets]);

  const themeClass = getThemeClass();
  const cssStyling = getCssStyling(themeStyles[themeClass]);

  const theme: Theme = {
    isDark: themeClass === "dark-theme",
    themeName: themeClass,
    primaryColor: cssStyling?.primaryColor,
    secondaryColor: cssStyling?.secondaryColor,
    primaryTextColor: cssStyling?.primaryTextColor,
    secondaryTextColor: cssStyling?.secondaryTextColor,
    cardColor: cssStyling?.cardColor,
    borderRadius: Number(cssStyling?.borderRadius),
  };

  const countryOverride = country ?? selectedCountry?.countryCode.toLowerCase();
  const checkoutUrl = countryOverride
    ? `${endpoints.checkoutIntent}?country=${countryOverride}`
    : endpoints.checkoutIntent;

  const { data, isLoading, error, isError, mutate } = usePost<
    CheckoutIntentResponse,
    CheckoutIntentResponse
  >(
    checkoutUrl,
    {},
    {
      onramp: selectedOnramp?.id.toLowerCase(),
      source:
        transactionType === "sell" ? selectedCrypto?.id : selectedFiat?.id,
      destination:
        transactionType === "sell" ? selectedFiat?.id : selectedCrypto?.id,
      amount: transactionType === "sell" ? cryptoAmount : fiatAmount,
      type: transactionType ?? "buy",
      paymentMethod: selectedPaymentMethod?.paymentTypeId,
      network: selectedCrypto?.network,
      uuid: cookies.uuid ?? "",
      originatingHost: params.originatingHost,
      wallet: walletAddress
        ? {
            address: walletAddress,
            memo: walletAddressTag,
          }
        : null,
      partnerContext: partnerContext ?? undefined,
      supportedParams: {
        theme,
        language: getLanguage(),
        partnerData: {
          redirectUrl: {
            success: successRedirectUrl,
            failure: failureRedirectUrl,
          },
          offrampCashoutRedirectUrl,
        },
        isRecurringPayment,
      },
      metaData: {
        quoteId: selectedOnramp?.quoteId,
        recurringPayment: {
          startDate: recurringPaymentMetadata?.startDate,
          time: recurringPaymentMetadata?.time,
          period: recurringPaymentMetadata?.period,
        },
      },
      signature: signature ?? "",
      signContent: signContent ?? "",
      allowOnrampPmSelection:
        skipTransactionScreen && !txnPaymentMethod ? true : false,
    }
  );

  return { checkoutIntentResponse: data, isLoading, error, isError, mutate };
};
