import React, { FC, ReactNode } from "react";
import styles from "./ViewHeader.module.css";
import { ReactComponent as LeftArrow } from "../../../icons/left-arrow.svg";

type ViewHeader = {
  backButtonAction: () => void;
  content: ReactNode;
};

const ViewHeader: FC<ViewHeader> = (props: ViewHeader) => {
  const { backButtonAction, content } = props;
  return (
    <div className={styles["view-header"]}>
      <div
        className={styles["view-header-arrow"]}
        onClick={() => backButtonAction()}
      >
        <LeftArrow height="24" width="24" />{" "}
      </div>
      <div className={styles["view-header-text"]}>{content}</div>
    </div>
  );
};

export default ViewHeader;
