import { IntegrationAccessToken } from "@meshconnect/web-link-sdk";
import { useEffect, useState } from "react";
import { useFetch } from "../utils/reactQuery";

export const useMeshFetchAccessTokens = (endpoint: string) => {
  const [accessTokens, setAccessTokens] = useState<
    IntegrationAccessToken[] | null
  >(null);
  const [tokensFetched, setTokensFetched] = useState(false);
  const { data, isLoading, isError } = useFetch<any>(endpoint);

  useEffect(() => {
    if (!isLoading) {
      if (isError || !data) {
        setTokensFetched(true);
        setAccessTokens(null);
        return;
      }

      const fetchedTokens =
        data.flatMap((item: any) =>
          item.accountTokens.map((accountToken: any) => ({
            accountId: accountToken.account.accountId,
            accountName: accountToken.account.accountName,
            accessToken: accountToken.accessToken,
            brokerType: item.brokerType,
            brokerName: item.brokerName,
          })),
        ) || [];

      setAccessTokens(fetchedTokens.length > 0 ? fetchedTokens : null);
      setTokensFetched(true);
    }
  }, [data, isLoading, isError]);

  return { accessTokens, tokensFetched };
};
