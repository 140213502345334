import { ListItem } from '@onramper/oui';
import React, { FC, useCallback, useState } from 'react';

import { ReactComponent as CheckMark } from '../../../../icons/check-mark.svg';


import { isSearchResultFound } from '../../../../utils/commonUtils';
import BottomOverlay from '../../../views/BottomOverlay/BottomOverlay';
import SelectionView from '../../../views/SelectionView/SelectionView';
import { SupportedLocales } from '../../../../types';
import { useLanguage } from '../../../../hooks/useLanguage';

export type LanguagePickerProps = {
  title: string;
  locales: SupportedLocales[];
  onSelectLanguage: (item: SupportedLocales, selectedIndex?: number) => void;
};

const LanguagePicker: FC<LanguagePickerProps> = (props: LanguagePickerProps) => {
  const { title, locales, onSelectLanguage } = props;
  const [searchedItems, setSearchedItems] = useState<SupportedLocales[] | undefined>();
  const { getLanguage } = useLanguage();


  const LanguageSearchHandler = useCallback(
    (searchKey: string) => {
      const searchResults = [...locales].filter(
        (item) => isSearchResultFound(item.localeCode, searchKey) || isSearchResultFound(item.localeName, searchKey),
      );
      setSearchedItems(searchResults);
    },
    [locales],
  );

  const renderCheckIcon = (localeCode: string) =>
  getLanguage()  === localeCode ? <CheckMark stroke="var(--primary-color)" /> : <></>;

  return (
    <BottomOverlay title={title}>
      <SelectionView<SupportedLocales>
        keyExtractor={({ localeCode }) => localeCode}
        items={searchedItems ?? locales}
        searchable
        onSelectItem={onSelectLanguage}
        onSearch={LanguageSearchHandler}
        renderItem={({ localeCode, localeName }, index) => (
          <ListItem
            key={index}
            primaryText={localeName}
            // secondaryText={countryCode}
            // primaryIcon={
            //   <Icon
            //     iconSrc={`https://hatscripts.github.io/circle-flags/flags/${countryCode.toLowerCase()}.svg`}
            //     size="medium"
            //     avatarName={countryCode.toLowerCase()}
            //   />
            // }
            secondaryIcon={renderCheckIcon(localeCode)}
          />
        )}
      />
    </BottomOverlay>
  );
};
export default LanguagePicker;
