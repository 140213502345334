import * as endpoints from '../config/endpoints';
import { Defaults, TransactionType } from '../types';
import { useFetch } from '../utils/reactQuery';

type DefaultsResponse = {
  message: {
    recommended: Defaults;
    defaults: Record<string, Defaults>;
  };
};

type FetchParams = {
  country?: string;
  type: TransactionType;
};

export const useDefaults = (country?: string | undefined | null, type?: TransactionType) => {
  const fetchParams: FetchParams = {
    type: type ?? 'buy',
  };

  if (country) {
    fetchParams.country = country;
  }

  const { data, isLoading, refetch } = useFetch<DefaultsResponse>(`${endpoints.defaultValues}/all`, fetchParams, {
    enabled: false,
  });

  return {
    countryDefault: data?.message?.recommended,
    defaults: data?.message?.defaults,
    isLoading,
    refetch,
  };
};
