import { openDB } from "idb";

const DB_NAME = "ONRAMPER_WIDGET_V2_DB";
const STORE_NAME = "keyvaluestore";
const AUTH_TOKEN_KEY = "authToken";
const ONRAMPER_ID_KEY = "onramperId";
const broadcastChannel = new BroadcastChannel("indexeddb_changes");

const openDatabase = async () => {
  try {
    const db = await openDB(DB_NAME, 1, {
      upgrade(db) {
        db.createObjectStore(STORE_NAME);
      },
    });
    return db;
  } catch (error) {
    console.error("Error opening IndexedDB database:", error);
    return null;
  }
};

const indexedDbApi = {
  setItem: async (key: IDBKeyRange | IDBValidKey | undefined, value: any) => {
    const db = await openDatabase();
    db && (await db.put(STORE_NAME, value, key));
  },

  getItem: async (key: IDBKeyRange | IDBValidKey) => {
    const db = await openDatabase();
    const storedValue = db && (await db.get(STORE_NAME, key));
    return storedValue;
  },

  removeItem: async (key: IDBKeyRange | IDBValidKey) => {
    const db = await openDatabase();
    db && (await db.delete(STORE_NAME, key));
  },

  setAuthToken: async (token: any) => {
    await indexedDbApi.setItem(AUTH_TOKEN_KEY, token);
    broadcastChannel.postMessage({ type: "ADD_ITEM", token });
  },

  getAuthToken: async () => {
    return await indexedDbApi.getItem(AUTH_TOKEN_KEY);
  },

  removeAuthToken: async () => {
    await indexedDbApi.removeItem(AUTH_TOKEN_KEY);
  },

  setOnramperId: async (oId: any) => {
    await indexedDbApi.setItem(ONRAMPER_ID_KEY, oId);
  },

  getOnramperId: async () => {
    return await indexedDbApi.getItem(ONRAMPER_ID_KEY);
  },

  removeOnramperId: async () => {
    await indexedDbApi.removeItem(ONRAMPER_ID_KEY);
  },

  getAuthTokenAndOnramperId: async () => {
    const [authToken, onramperId] = (await Promise.all([
      indexedDbApi.getAuthToken(),
      indexedDbApi.getOnramperId(),
    ])) as [string, string];

    return [authToken, onramperId];
  },
};

export default indexedDbApi;
