import { useCallback, useEffect, useState } from 'react';

export const useThirdPartyCookieCheck = () => {
  const [isSupported, setIsSupported] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const frame = document.createElement('iframe');
    frame.id = '3pc';
    frame.src = 'https://check-cookie.onramper.tech';
    frame.style.display = 'none';
    frame.style.position = 'fixed';
    document.body.appendChild(frame);

    window.addEventListener(
      'message',
      function listen(event) {
        if (event.data === '3pcSupported' || event.data === '3pcUnsupported') {
          setIsSupported(event.data === '3pcSupported');
          document.body.removeChild(frame);
          window.removeEventListener('message', listen);
        }
      },
      false,
    );
  }, []);

  const is3PCBlockedOnramp = useCallback(
    (onramp: string) => {
      const blockedOnramps: string[] =
        process.env.REACT_APP_STAGE === 'dev' ? ['alchemypay', 'binance'] : ['alchemypay', 'moonpay', 'binance'];
      return isSupported === false && blockedOnramps.includes(onramp);
    },
    [isSupported],
  );

  return { isSupported, is3PCBlockedOnramp };
};
