import { useEffect } from "react";
import { v4 as uuidv4, validate as uuidv4Validate } from "uuid";
import { useCookies } from "react-cookie";

const useUUIDCookie = (uuidFromUrl: string | undefined) => {
  const [cookies, setCookie] = useCookies(["uuid"]);

  useEffect(() => {
    const setUUIDCookie = (uuid: string) => {
      setCookie("uuid", uuid, { maxAge: 126144000 }); // 4 years in seconds
    };

    // Check if UUID is provided in URL or set a new one if missing
    if (uuidFromUrl) {
      // const isUUIDValid = uuidv4Validate(uuidFromUrl);
      const isUUIDValid = uuidFromUrl.length > 0;

      if (isUUIDValid) {
        setUUIDCookie(uuidFromUrl);
      } else {
        console.warn("Invalid UUID provided.");
        !cookies.uuid && setUUIDCookie(uuidv4());
      }
    } else if (!cookies.uuid) {
      // No UUID in URL or cookies, generate a new one
      setUUIDCookie(uuidv4());
    }
  }, [uuidFromUrl, cookies.uuid, setCookie]);
};

export default useUUIDCookie;
