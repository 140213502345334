import React, { createContext, FC, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { ChildrenProps } from '../../types';
import { useParamContext } from '../ParamContextProvider';
import { initialState } from './initialState';
import { themeReducer } from './reducers';
import { ThemeActionType, ThemeContextProps, ThemeName } from './types';

const initialContext: ThemeContextProps = {
  theme: { name: 'light' },
  setThemeName: () => undefined,
};

const ThemeContext = createContext<ThemeContextProps>(initialContext);

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeContextProvider');
  }
  return context;
};

export const ThemeContextProvider: FC<ChildrenProps> = (props: ChildrenProps) => {
  const [theme, dispatch] = useReducer(themeReducer, initialState);
  const { params } = useParamContext();
  const { themeName } = params;

  const setThemeName = useCallback(
    (themeName: ThemeName) => {
      dispatch({
        type: ThemeActionType.SetThemeName,
        payload: themeName,
      });
    },
    [dispatch],
  );

  useEffect(() => {
    themeName && setThemeName(themeName);
  }, [themeName, setThemeName]);

  const value = useMemo(() => ({ theme, setThemeName }), [theme, setThemeName]);

  return <ThemeContext.Provider value={value}>{props.children}</ThemeContext.Provider>;
};
