import { Theme, ThemeActions, ThemeActionType } from './types';

export const themeReducer = (state: Theme, action: ThemeActions) => {
  switch (action.type) {
    case ThemeActionType.SetThemeName:
      return { ...state, name: action.payload };

    default:
      throw new Error('Unhandled action type');
  }
};
