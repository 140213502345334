import React, { useEffect } from "react";
import { useAuthContext } from "../../providers/AuthContextProvider";
import { useIndexedDbContext } from "../../providers";
import LoadingView from "../views/LoadingView";
import { SignIn } from "../views/SignInView";

const withAuth = (
  WrappedComponent: React.ComponentType,
  showSignIn: boolean
) => {
  const WithAuthComponent: React.FC = (props) => {
    const { authToken, isLoading } = useIndexedDbContext();
    const { auth, setIsAuthenticated } = useAuthContext();
    const { isAuthenticated } = auth;

    useEffect(() => {
      if (authToken) setIsAuthenticated(true);
    }, [authToken]);

    if (isLoading && !isAuthenticated) return <LoadingView />;

    if (!isAuthenticated && showSignIn) {
      //This is to handle signin while checking out
      return <SignIn signInFrom="checkout" />;
    }
    return <WrappedComponent {...props} />;
  };

  return WithAuthComponent;
};

export default withAuth;
