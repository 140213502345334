import { CryptoCurrency } from "../types";

export const getWalletAddress = (wallets: string, selectedCrypto: string) => getWalletMap(wallets)[selectedCrypto];

export const getNetworkWalletAddress = (networkWallets: string, selectedNetwork: string) =>
  getNetworkWalletsMap(networkWallets)[selectedNetwork];

export const getWalletAddressTag = (walletAddressTags: string, selectedCrypto: string) =>
  getWalletAddressTagMap(walletAddressTags)[selectedCrypto];

const getWalletMap = (walletString: string) => {
  const wallets: { [key: string]: string } = {};
  walletString.split(',').forEach((walletAddress: string) => {
    const [tokenId, address] = splitOnFirstColon(walletAddress);
    if (tokenId.length > 0 && address.length > 0) {
      wallets[tokenId.toLowerCase()] = address;
    }
  });
  return wallets;
};

const getNetworkWalletsMap = (walletString: string) => {
  const networkWallets: { [key: string]: string } = {};
  walletString.split(',').forEach((walletAddress: string) => {
    const [networkId, address] = splitOnFirstColon(walletAddress);
    if (networkId.length > 0 && address.length > 0) {
      networkWallets[networkId.toLowerCase()] = address;
    }
  });
  return networkWallets;
};

const getWalletAddressTagMap = (walletAddressTagString: string) => {
  const walletTags: { [key: string]: string } = {};
  walletAddressTagString.split(',').forEach((walletAddressTag: string) => {
    const [tokenId, addressTag] = splitOnFirstColon(walletAddressTag);
    if (tokenId.length > 0 && addressTag.length > 0) {
      walletTags[tokenId.toLowerCase()] = addressTag;
    }
  });
  return walletTags;
};

function splitOnFirstColon(input: string): [string, string] {
  const index = input.indexOf(':');
  if (index === -1) {
    // If ':' is not found, return the original string and an empty string
    return [input, ''];
  } else {
    // Split the string on the first occurrence of ':'
    const firstPart = input.slice(0, index);
    const secondPart = input.slice(index + 1);
    return [firstPart, secondPart];
  }
}

export const getDisplayWalletAddress = (address?: string) => {
  if (address && address.length > 0) {
    const firstFive = address.slice(0, 7);
    const lastFour = address.slice(-7);

    return `${firstFive} ... ${lastFour}`;
  }
};

export const getResolvedWalletData = (
  wallets: string | null | undefined,
  networkWallets: string | null | undefined,
  walletAddressTags: string | null | undefined,
  selectedCrypto: CryptoCurrency | null
): { walletAddress: string | null; walletAddressTag: string | null } => {
  let walletAddress: string | null = null;
  let walletAddressTag: string | null = null;

  if (wallets) {
    walletAddress = getWalletAddress(
      wallets,
      selectedCrypto?.id.toLowerCase() ?? ""
    );
    if (walletAddressTags) {
      walletAddressTag = getWalletAddressTag(
        walletAddressTags,
        selectedCrypto?.id.toLowerCase() ?? ""
      );
    }
  } else if (networkWallets) {
    walletAddress = getNetworkWalletAddress(
      networkWallets,
      selectedCrypto?.network.toLowerCase() ?? ""
    );
  }

  return { walletAddress, walletAddressTag };
};