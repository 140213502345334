import { datadogRum } from '@datadog/browser-rum';
import React, { useEffect } from 'react';

interface DatadogRUMProviderProps {
  uuid: string;
  publicKey: string;
  originatingHost: string;
  children: React.ReactNode;
}

export function DatadogRUMProvider({ children, uuid, publicKey, originatingHost }: DatadogRUMProviderProps) {
  useEffect(() => {
    datadogRum.init({
      applicationId: process.env.REACT_APP_RUM_APP_ID ?? '',
      clientToken: process.env.REACT_APP_RUM_CLIENT_TOKEN ?? '',
      site: 'datadoghq.com',
      service: 'widget-v2',
      env: process.env.REACT_APP_STAGE === 'prod' ? 'prod' : 'stg',
      sessionSampleRate: 20,
      sessionReplaySampleRate: 10,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
    datadogRum.setUser({
      id: uuid,
    });
    datadogRum.setUserProperty('publicKey', publicKey);
    datadogRum.setUserProperty('originatingHost', originatingHost);
    datadogRum.startSessionReplayRecording();
  }, [uuid, publicKey, originatingHost]);

  return <>{children}</>;
}
